<template>
  
  <div style="display: flex; overflow: hidden; height: calc(100vh - 65px);">
    
    <div class="accent-gradient hide-if-mobile" style="width: 100%; overflow: hidden;"> 
    </div>
    
    <div class="container-medium" style="display:flex;margin: 0 auto;">

      <div class="center container-medium" style="padding:0px 62px; text-align: start;">

        <h1 class="left" style="margin-top:0;">{{$t('login.title')}}</h1>

        <el-row>
          <el-input v-model="email" placeholder="Email" required :disabled="loading || app.callbackWaiting" />
          <p v-if="!app.callbackWaiting && app.validateEmail(email)" class="text-small" v-html="$t('login.we_will_send_verification')"/>
        </el-row>

        <el-row v-if="!app.callbackWaiting">
          <el-button type="primary" :loading="loading" v-on:click="performLogin" style="padding: 0px 60px;">{{$t('common.login')}}</el-button>
        </el-row>

        <p v-if="!app.callbackWaiting" class="text-small" v-html="$t('login.privacy_and_terms')"></p>

        <el-row v-if="app.callbackWaiting">
          <p class="text-small">
            <span v-html="$t('login.tap_on_link')"></span>
            <span style="margin-left:4px;">
              <el-link :underline="false" class="clickable dont-break-out text-small" style="margin: 0;" v-if="app.callbackWaiting" v-on:click="cancelLogin"><b> {{$t('common.cancel')}}</b></el-link>
            </span>
          </p>
        </el-row>

        <el-divider />

        <div>      
          <img @click="performAppleLogin" :src="$t('resources.apple-sign-in-button')" class="img-button button noselect" oncontextmenu="return false;"/>
          <img @click="performGoogleLogin" :src="$t('resources.google-sign-in-button')" class="img-button button noselect" oncontextmenu="return false;"/>       
        </div>

      </div>
    </div>

  </div>

</template>

<style scoped>
.img-button {
  height:50px;
  cursor: pointer;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
}

.el-main {
  height: 90vh;
  overflow: hidden;
}
.container {
  max-width: 280px;
}
.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  overflow: hidden;
}
</style>

<script>
import { heartApp } from "@/main.js";
import { performCatchable } from "@/app/helpers.js"
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

export default {
  name: "AccountLogin",
  onlyAnonymous: true,

  data() {
    return {
      app: heartApp,
      email: null,
      loading: false,
      loginCallbackInterval: null,
      googleToken: null,
      previousCallbackWaiting: null,
    };
  },

  watch: {
    'app.callbackWaiting': {
      handler: function (newValue) {
        if (newValue === true) {
          this.startPolling();
        }
        else {
          this.stopPolling();
        }    
      },
      immediate: true 
    }
  },

  created() {
    this.app.created(this);
  },

  mounted() {
    if (this.app.platform == 'web') {
      window.handleCredentialResponse = async (response) => {
        await this.googleCallback(response);
      }

      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
      document.head.appendChild(externalScript)
    }
  },

  unmounted() {
    this.stopPolling();
  },

  methods: {
    async performAppleLogin() {

      let options = {
        clientId: 'com.banana.heartbit-service',
        redirectURI: 'https://heart-bit.online/login',
        scopes: 'email name'
      };

      SignInWithApple.authorize(options)
        .then((result) => {
          // Handle user information
          // Validate token with server and create new session
          console.log(result);

          this.appleToken = result.response.identityToken;
          this.performLogin();
        })
        .catch(error => {
          // Handle error
          console.log(error)
        });
    },

    async performGoogleLogin() {
      const response = await GoogleAuth.signIn();
      console.log(response);

      this.googleToken = response.authentication?.idToken ?? response.idToken;
      await this.performLogin();
    },

    async googleCallback(response) {
      this.googleToken = response.credential;
      await this.performLogin();
    },

    async performLogin() {
      this.loading = true;

      await performCatchable(async () => {
        await this.app.login(this.email, this.googleToken, this.appleToken);
      });

      this.loading = false;
    },

    cancelLogin() {
      this.app.cancelLogin();
    },

    async startPolling() {
      this.email = localStorage.getItem('email');
      this.loading = true;
      await this.loginCallback();
      document.addEventListener("visibilitychange", this.loginCallback);
      this.loginCallbackInterval = setInterval(this.loginCallback, 5000);
    },

    async stopPolling() {
      this.loading = false;
      clearInterval(this.loginCallbackInterval);
      document.removeEventListener("visibilitychange", this.loginCallback);
    },

    async loginCallback() {
      await performCatchable(async () => {
        await this.app.loginCallback();
      });
    },
  },
};
</script>