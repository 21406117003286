<template>

  <div style="display:flex;">

    <!-- <el-menu
      default-active="2"
      :collapse="true"
      class="el-menu-vertical-demo">

      <el-menu-item index="1" @click="$router.push('/job')">
        <el-icon><Plus /></el-icon>
        <template #title>{{$t('dashboard.create_job')}}</template>
      </el-menu-item>
      <el-menu-item index="2" @click="$router.push('/dashboard')">
        <el-icon><House /></el-icon>
        <template #title>{{$t('dashboard.dashboard')}}</template>
      </el-menu-item>
      <el-menu-item index="3" @click="$router.push('/account')">
        <el-icon><setting /></el-icon>
        <template #title>{{$t('common.account')}}</template>
      </el-menu-item>

    </el-menu> -->

  <div class="full-width" style="overflow: hidden;">
    <el-drawer v-model="beatInfoDrawer" direction="btt" size="100%" style="--el-drawer-padding-primary:0;">
      
      <div style="height: 100%;" v-loading="drawerLoading">

        <el-row>
          <h2 class="label">{{selectedJob.name}}</h2>
        </el-row>

        <el-row>
            <p class="color-secondary label clickable">         
              <span>
                <el-icon v-if="selectedBeat.icon == 'progress'" class="color-navy" style="margin-top:2.5px;"><Clock /></el-icon>
                <el-icon v-else-if="selectedBeat.icon == 'changes'" class="color-navy" style="margin-top:2.5px;"><MagicStick /></el-icon>
                <el-icon v-else-if="selectedBeat.icon == 'warning'" class="color-primary" style="margin-top:2.5px;"><Warning /></el-icon>
                <el-icon v-else-if="selectedBeat.icon == 'success'" class="color-navy" style="margin-top:2.5px;"><Check /></el-icon>
                {{selectedBeat.shortDescription}}
              </span>
            </p>
          </el-row>

        <el-row>
            <p class="color-secondary label clickable">
              <span :class="selectedBeat.visualChanges === true || selectedBeat.httpStatusChanges === true ? `bold ${selectedBeat.success ? 'color-navy': 'color-primary'}` : ''">{{formatBeatTime(selectedBeat.created)}}</span> 
              <span class="color-thirtly"> ∙ {{formatRelativeNow(selectedBeat.created)}}</span>
            </p>
        </el-row>

        <el-row>
          <div style="background: #fafafa; padding:32px 0px; margin-bottom: 62px; width: 100%;">

            <!-- <div class="center placeholder shadow">
              <a :href="selectedBeat.screenshotUrl" target="_blank">
                <el-image
                  style="width: 100%;padding:0; --el-main-padding:0;"
                  :src="selectedBeat.screenshotUrl"
                  :fit="contain">
                  <template #error>
                    <div class="image-slot">
                      <el-icon><icon-picture /></el-icon>
                    </div>
                  </template>
                </el-image>
              </a>
            </div> -->

            <div style="height: 100vh;">
            <div class="parent">
              
              <!-- <a class="image1" :href="previousBeat.screenshotUrl" target="_blank" style="background-color: #ff000022; width: 100%; max-width: 100%;"> -->
                <el-image class="image1"
                  :src="previousBeat.screenshotUrl"
                  :fit="contain">
                  <template #error>
                    <div class="image-slot">
                      <el-icon><icon-picture /></el-icon>
                    </div>
                  </template>
                </el-image>
              <!-- </a> -->

              <div class="image2" style="background-color:rgba(0, 0, 0, 0.2); width:100%; height:100%;"></div>

              <!-- <a class="image2" :href="selectedBeat.changesScreenshotUrl" target="_blank" @mousemove="mouseMove" style="width: 100%; max-width: 100%;"> -->
                <el-image class="image2"
                  :style="selectedBeat.imageStyle"
                  :src="selectedBeat.changesScreenshotUrl"
                  :fit="contain">
                  <template #error>
                    <div class="image-slot">
                      <el-icon><icon-picture /></el-icon>
                    </div>
                  </template>
                </el-image>
              <!-- </a> -->

              <div :style="selectedBeat.lineStyle"></div>

              <div class="image2" @mousemove="mouseMove" style="width:100%; height:100%;"></div>

            </div>
            </div>

            <!-- <div class="center placeholder shadow" :style="selectedBeat.containerStyle">
              
              <div :style="selectedBeat.lineStyle"></div>
            </div> -->

          </div>
        </el-row>

        <!-- <el-row>
          <el-button type="primary"><el-icon style="margin-right:12px;"><Check /></el-icon> Good alert</el-button>
          <el-button type="primary"><el-icon style="margin-right:12px;"><Close /></el-icon> Wrong alert</el-button>
        </el-row> -->

      </div>
    </el-drawer>

    <div class="full-width" style="display: flex;">

      <el-scrollbar v-show="this.selectedJob == null || this.app.screenWidth >= 1010" class="dashboard-menu" height="calc(100vh - var(--header-menu-height))" v-loading="loading">

        <div>
          <el-row style="padding: 12px 24px 0px 24px;">
            <h2 class="noselect" style="padding-right: 24px; margin: 12px 0px; display: inline-block; vertical-align: middle;">{{$t('dashboard.dashboard')}}</h2>
            <!-- <el-button type="primary" plain><el-icon style="margin-right:12px;"><Refresh /></el-icon> Run all</el-button> -->

            <el-button style="margin: auto 0;" type="primary" @click="$router.push('/job')"><el-icon style="margin-right:12px;"><Plus /></el-icon> {{$t('dashboard.create_job')}}</el-button>

          </el-row>

          <!-- <el-row style="padding: 6px 24px 0px 24px;">
            <el-input :placeholder="$t('dashboard.search_job_placeholder')">
              <template #prefix>
                <el-icon class="el-input__icon"><search /></el-icon>
              </template>
            </el-input>
          </el-row> -->
        </div>

        <p class="noselect color-secondary text-small bold" style="text-align:start; padding: 0px 24px;">{{$t('dashboard.active_jobs')}} ({{jobs.length}})</p>

        <div>

          <el-empty v-if="!loading && jobs != null && jobs.length == 0" :description="$t('dashboard.no_jobs')" />

          <div v-for="job in jobs" :key="job.url">
            <div class="job-item-container noselect clickable el-menu-item" :class="selectedJob === job ? 'selected' : ''" v-on:click="changeSelectedJob(job)">
              
              <el-image class="job-favicon" :src="job.faviconUrl" fit="cover">
                <template #error>
                  <div class="image-slot"></div>
                </template>
              </el-image>

              <!-- <el-icon class="color-blue" style="width:16px;"><Check /></el-icon> -->
              <!-- <div class="success-marker" style="margin-top: -6px;"></div> -->
              <div class="tariff-heading" style="overflow: hidden; padding-left:12px;">
                <p class="noselect bold ellipsis">{{job.name}}</p>
                <p class="noselect color-secondary text-small ellipsis" style="margin-top:2px;">{{job.url}}</p>
              </div>


            </div>
          </div>
        </div>
      </el-scrollbar>

      <div class="dashboard-main">

              <div v-if="selectedJob" class="tariff" style="display: flex; flex-flow: column;  height: 100%;">
                
                    <div id="beatsScrollContainer" style="flex: 1 1 auto;">
                      <div :style="{ height: beatsScrollContainerHeight }">
                        <el-scrollbar>
                          <div v-infinite-scroll="loadBeats">

                            <div style="flex: 0 1 auto; padding:0px 32px; ">
                              <el-row style="margin-top: 32px;">
                                  <div class="tariff-heading">
                                    <!-- <el-icon :size="24" v-on:click="changeSelectedJob(null)"><Back /></el-icon> -->
                                    <h2 class="container-medium">{{selectedJob.name}}</h2>
                                    <el-link target="_blank" :underline="false" class="color-secondary clickable dont-break-out" :href="selectedJob.url"><span style="max-width: 600px;">{{selectedJob.url}}</span> <el-icon style="margin-left:6px;"><Link /></el-icon></el-link>
                                  </div>
                              </el-row>

                  
                              <p class="color-secondary text-small">{{$t('dashboard.next_check')}}</p>
                              
                              <el-row>
                                  <el-icon style="margin-right:8px; margin-top:2px;"><Clock /></el-icon>
                                  <p class="label"><span class="bold">{{formatBeatTime(selectedJob.nextCheck)}}</span>
                                  <span class="color-secondary "> ∙ {{formatRelativeNow(selectedJob.nextCheck)}}</span>
                                  </p>
                              </el-row>

                              <el-row>
                                  <el-button v-on:click="jobEnqueueCheck(selectedJob)" :loading="selectedJob.loading" type="primary" text bg><el-icon v-if="!selectedJob.loading" style="margin-right:8px;"><RefreshRight /></el-icon> {{$t('dashboard.check_now')}}</el-button>
                              </el-row>

                              <el-divider />

                              <el-row>
                                <div>
                                  <p class="color-secondary text-small" style="margin-top:0;">{{$t('dashboard.checks_log')}}</p>
                                  <!-- <el-switch class="bold" :active-text="$t('dashboard.show_only_changes')" v-model="onlyChanges"/> -->
                                </div>
                              </el-row>

                            </div>

                              <div style="display: flex; padding: 6px 6px;" v-for="(beat, index) in selectedJob.beats" :key="beat.id">

                                  <!-- <div class="vl" :class="selectedJob.beats.at(index-1).success === true ? 'vl-color-default': 'vl-color-primary'"></div> -->


                                  <!-- {{index}} -->
                                  <!-- {{beat.success}} -->
                                  <el-icon v-if="beat.icon == 'progress'" class="color-navy" style="margin-top:2.5px;"><Clock /></el-icon>
                                  <el-icon v-else-if="beat.icon == 'changes'" class="color-navy" style="margin-top:2.5px;"><MagicStick /></el-icon>
                                  <el-icon v-else-if="beat.icon == 'warning'" class="color-primary" style="margin-top:2.5px;"><Warning /></el-icon>
                                  <el-icon v-else-if="beat.icon == 'success'" class="color-navy" style="margin-top:2.5px;"></el-icon>
                                  
                                  <div class="beat-row-content">  
                                    <!-- <el-tooltip class="box-item" raw-content effect="dark" :content="beat.shortDescription" :disabled="!shouldShowTooltip(beat)" placement="right"> -->
                                      <p v-on:click="changeSelectedBeat(beat, index)" class="label clickable">
                                        <span :class="beat.visualChanges === true || beat.httpStatusChanges === true ? `${beat.success ? '': 'color-primary'}` : ''">{{formatBeatTime(beat.created)}}</span> 
                                        <span class="color-thirtly"> ∙ {{formatRelativeNow(beat.created)}}</span>
                                        <span class="color-secondary text-small" v-if="shouldShowTooltip(beat)"><br/>{{beat.shortDescription}}</span>
                                      </p>
                                    <!-- </el-tooltip> -->

                                    <div v-if="beat.visualChanges === true || beat.httpStatusChanges === true" style="margin-top: 6px;" >
                                      <!-- <el-button text bg class="text-small" v-on:click="changeSelectedBeat(beat, index)">{{$t('dashboard.details')}}</el-button> -->
                                      <!-- <el-button v-if="beat.visualChanges === true" type="primary" text bg class="bold"><el-icon style="margin-right:12px;"><MagicStick /></el-icon>Visual changes</el-button>
                                      <el-button v-if="beat.httpStatusChanges === true" type="primary" text bg class="bold"><el-icon style="margin-right:12px;"><Refresh /></el-icon>HTTP status change</el-button>
                                    -->
                                    </div>
                                  </div>
                                  <!-- <el-divider v-if="onlyChanges" style="margin: 6px 0px 0px 24px;" /> -->
                                </div>

                              <p v-loading="beatsLoading" style="max-width: 200px; padding: 32px;"></p>
                          </div>
                        </el-scrollbar>
                      </div>
                    </div>
                      
              </div>
   
      </div>

      <el-menu v-if="selectedJob" style="margin-left: auto; box-shadow: rgb(130 148 161 / 40%) 2px 1px 33px -19px;" collapse="true">
        <el-menu-item index="1" @click="$router.push(`/job#${selectedJob.id}`)">
          <el-icon><Setting /></el-icon>
          <template #title>{{$t('dashboard.job_edit')}}</template>
        </el-menu-item>
        <el-menu-item index="2">
          <el-icon><Bell /></el-icon>
          <template #title>{{$t('dashboard.edit_notifications')}}</template>
        </el-menu-item>
      </el-menu>
    
    </div>

  </div>

</div>

</template>

<style scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.vl-color-default {
  border: 1px solid #d7d7d7;
}
.vl-color-primary {
  border: 1px solid #d7d7d7;
}
.vl {
  margin-top: 21px;
    margin-left: 12px;
    margin-bottom: -9px;
    margin-right: -9px;
}
.color-navy {
  color: black;
}
.color-primary {
  color: var(--el-color-primary);
}
.color-blue {
  color: var(--blue);
}
.beat-row-content > p {
  margin: 0;
}
.beat-row-content {
  margin-left: 8px;
}
.el-menu-item {
  line-height: initial;
}
.link {
  color: var(--blue);
}
.selected {
  background: var(--el-color-primary-light-9);
}
.pointer {
  cursor: pointer;
}
.success-marker {
  background: lightgreen;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
}
.tariff-heading > p, h1, h2, h3 {
  margin:0;
  text-align: start;
}
h1 {
  font-weight: 800;
}
.tariff-legend {
  border:none; 
  box-shadow:none;
  text-align:end !important;  
}
.tariff-legend * {
  color:var(--el-text-color-secondary);
}
.tariff {
  text-align: start;
}
.el-divider {
  --el-border-color:var(--el-color-info-light-8);
}
.primary-color {
  color: var(--el-color-primary);
}

.job-favicon {
  width:19px; 
  height:19px; 
  min-width:19px; 
  min-height:19px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.job-item-container {
  display: flex; 
  flex-wrap: nowrap; 
  padding: 16px 24px 16px 18px;
  margin: 12px 20px;
  overflow-wrap: anywhere;
  border-radius: 12px;
}
.infinite-list {
  height: 200px;
}

.parent {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
.image1 {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<script>
import { heartApp } from "@/main.js";
import { performCatchable } from "@/app/helpers";
import { useBrowserLocation } from '@vueuse/core';

export default {
  name: "DashboardPage",
  onlyAuthorized: true,
  data() {
    return {
      app: heartApp,
      beatInfoDrawer: false,
      createJobDrawer: false,
      loading: false,
      drawerLoading: false,
      loadDisabled: false,
      beatsLoading: false,
      selectedJob: null,
      selectedBeat: null,
      previousBeat: null,
      onlyChanges: false,
      canLoadMore: true,
      jobs: [],
      beatsScrollContainerHeight: '120px'
    };
  },
  
  created() {
    this.app.created(this);
  },

  mounted() { 
    window.addEventListener("resize", this.updateBeatsContainerHeight);   
    this.updateBeatsContainerHeight();
  },

  unmounted() {
    window.removeEventListener("resize", this.updateBeatsContainerHeight);
  },

  beforeMount() {
    this.getJobs();
  },

  watch: {
    onlyChanges: function (value) {
        this.selectedJob.beats = [];
        this.canLoadMore = true;
        this.loadBeats();
    },
    $route () {
      this.onPathChanged();
    }
  },
  methods: {
    mouseMove(e) {
      this.selectedBeat.imageStyle.clipPath = `inset(0px 0px 0px ${e.offsetX}px)`;
      this.selectedBeat.lineStyle.left = `${e.x-20}px`;
      console.log(e);
    },

    shouldShowTooltip(beat) {
      return beat.shortDescription;
    },

    formatBeatTime(value){
      var date = new Date(value);
      if (this.isToday(value)) {
        return `${date.toLocaleTimeString(this.app.locale(), { hour: '2-digit', minute:'2-digit' })}`;
      }
      return `${date.toLocaleDateString(this.app.locale(), { day: 'numeric', month: 'long' })}, ${date.toLocaleTimeString(this.app.locale(), { hour: '2-digit', minute:'2-digit' })}`;
    },

    isToday (value)  {
      const today = new Date()
      const date = new Date(value);
      return date.getDate() == today.getDate()
        && date.getMonth() == today.getMonth()
        && date.getFullYear() == today.getFullYear()
    },

    formatRelativeNow(value){
      const date = new Date(value);
      const now = new Date();
      const future = date > now;

      var days = this.inDays(date, now);
      var hours = this.inHours(date, now);
      var minutes = this.inMinutes(date, now);

      if (future) {
        console.log(days);
        if (Math.abs(hours) < 1) {
          return `через ${Math.floor(Math.abs(minutes))} мин`;
        }

        if (Math.abs(days) < 1) {
          return `через ${Math.floor(Math.abs(hours))} ч`;
        }

        return `через ${Math.floor(Math.abs(days))} д`;
      }
      else { 
        if (minutes < 1) {
          return `только что`;
        }

        if (hours < 1) {
          return `${Math.floor(minutes)} мин назад`;
        }

        if (days < 1) {
          return `${Math.floor(hours)} ч назад`;
        }

        return `${Math.floor(days)} д назад`;
      }
    },

    inMinutes: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return (t2-t1)/(60*1000);
    },

    inHours: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return (t2-t1)/(3600*1000);
    },

    inDays(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return (t2-t1)/(24*3600*1000);
    },

    updateBeatsContainerHeight() {
      const height = document.getElementById('beatsScrollContainer')?.clientHeight ?? 0;
      this.beatsScrollContainerHeight = `${height}px`;
    },

    async loadBeats() {
      if (!this.canLoadMore) {
        return;
      }

      this.beatsLoading = true;

      var job = this.selectedJob;

      await performCatchable(async () => {
        if (job.beats == undefined) {
          job.beats = [];
        }

        var firstBeat = job.beats[0];
        var skip = job.beats.length;
        var limit = 20;
        
        var beats = await this.app.getBeats(job.id, firstBeat?.created, skip, limit, this.onlyChanges);
        job.beats = job.beats.concat(beats);

        this.canLoadMore = beats.length >= limit;
      });

      this.beatsLoading = false;
      this.updateBeatsContainerHeight();
    },

    async changeSelectedBeat(beat, index) {
      console.log(index);
      this.selectedBeat = beat;
      this.beatInfoDrawer = true;

      this.drawerLoading = true;

      await performCatchable(async () => {
        this.previousBeat = this.selectedJob.beats.at(index+1);

        beat.changesScreenshotUrl ??= URL.createObjectURL(await this.app.getBeatChangesScreenshot(beat.id));
        this.previousBeat.screenshotUrl ??= URL.createObjectURL(await this.app.getBeatScreenshot(this.previousBeat.id)) ?? beat.changesScreenshotUrl;

        beat.imageStyle = {
          //left: '-50%',
          //transform: 'translate(75%, 0%)',
          clipPath: 'inset(0px 0px 0px 100px)',
          '--el-main-padding':'0'
        };
        beat.lineStyle = {
          width: '2px',
          height: '100%',
          background: 'var(--el-color-primary)',
          position: 'absolute',
          top: '0',
        };
      });

      this.drawerLoading = false;
    },

    openCreateJobDrawer() {
      this.createJobDrawer = true;
    },

    async changeSelectedJob(job) {
      if (job == null) {
        await this.$router.go(-1);
        return;
      } 

      await this.$router.push({ hash: `#${job.id}` });
      this.onPathChanged();
    },

    /** 
     * To be called on url path changed to refresh selected job
     */
    onPathChanged() {
      console.log(this.$route.hash)
      const jobId = this.$route.hash.replace('#', '');
      this.selectedJob = this.jobs.find(job => job.id == jobId);
      this.canLoadMore = true;
      this.loadBeats();
    },

    async getJobs() {
        this.loading = true;

        await performCatchable(async () => {
            this.jobs = await this.app.getJobs();
        });

        this.loading = false;
        this.onPathChanged();
    },

    async jobEnqueueCheck(job) {
        job.loading = true;

        await performCatchable(async () => {
            await this.app.jobEnqueueCheck(job.id);
        });

        job.loading = false;
    },

  }
};
</script>