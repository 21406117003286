<template>

    <div>
        <div class="secondary-gradient">
            <div class="center landing-container" style="max-width: 700px;">
                <el-row style="padding:32px;">  
                    <h1>Пользовательское соглашение</h1>
                </el-row>
            </div>
        </div>

        <div class="center landing-container" style="max-width: 700px;"> 
            <div style="padding:32px;">
            <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем heart-bit.online (далее HeartBit или Администрация) с одной стороны и пользователем сайта с другой.</p>
            <p>Сайт HeartBit не является средством массовой информации.</p>
            <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения. Если Вы не согласны с условиями данного соглашения, не используйте сайт HeartBit.</p>

            <el-divider/>
            <h3>1. Пользователь имеет право</h3>
            <div class="li">- получать информацию на сайте;</div>
            <div class="li">- создавать информацию для сайта;</div>
            <div class="li">- копировать информацию на другие сайты с указанием источника;</div>
            <div class="li">- использовать информацию сайта в личных некоммерческих целях;</div>
            <div class="li">- использовать информацию сайта в коммерческих целях с разрешения Администрации;</div>

            <el-divider/>
            <h3>2. Администрация имеет право</h3>
            <div class="li">- по своему усмотрению и необходимости создавать, изменять, отменять правила;</div>
            <div class="li">- ограничивать доступ к любой информации на сайте;</div>
            <div class="li">- создавать, изменять, удалять информацию;</div>
            <div class="li">- удалять учетные записи;</div>
            <div class="li">- отказывать в регистрации без объяснения причин;</div>

            <el-divider/>
            <h3>3. Пользователь обязуется</h3>
            <div class="li">- не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность;</div>
            <div class="li">- не нарушать работоспособность сайта;</div>
            <div class="li">- не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу;</div>
            <div class="li">- не совершать действия, направленные на введение других Пользователей в заблуждение;</div>
            <div class="li">- не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам;</div>
            <div class="li">- не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ;</div>
            <div class="li">- не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ;</div>
            <div class="li">- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами;</div>

            <el-divider/>
            <h3>4. Администрация обязуется</h3>
            <div class="li">- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</div>
            <div class="li">- осуществлять разностороннюю защиту учетной записи Пользователя</div>
            <div class="li">- защищать информацию, распространение которой ограничено или запрещено законами путем вынесения предупреждения либо удалением учетной записи пользователя, нарушившего правила</div>
            <div class="li">- предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом</div>

            <el-divider/>
            <h3>5. Ответственность сторон</h3>
            <div class="li">- пользователь лично несет полную ответственность за распространяемую им информацию</div>
            <div class="li">- администрация не несет никакой ответственности за достоверность информации, скопированной из других источников</div>
            <div class="li">- администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг</div>
            <div class="li">- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</div>
            <div class="li">- в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т.д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса</div>

            <el-divider/>
            <h3>6. Правила осуществления платежей и возврата средств</h3>
            
            <p>Сайт может сейчас или в будущем взимать плату за определенные предложения услуг либо на разовой основе, либо на основе подписки. Когда вы покупаете платную услугу, у вас есть возможность просмотреть и принять комиссию, которая будет взиматься с вас, которая может время от времени меняться.</p>
            
            <p>Изменения в тарифном плане вступают в силу с момента публикации изменений на Сайте. Сайт может решить временно изменить плату за услуги для рекламных мероприятий или новых услуг, и такие изменения вступают в силу, когда такие временные рекламные мероприятия или новые услуги публикуются на Сайте.</p>
            
            <p>Когда вы покупаете какую-либо платную услугу, вы разрешаете Сайту или ее сторонним платежным системам взимать с указанной вами кредитной карты полную сумму применимых сборов и всех применимых налогов, и вы заявляете и гарантируете, что вы уполномочены использовать такую кредитную карту для покупки такой услуги. </p>
            
            <p>Вы также разрешаете Сайт хранить информацию о вашей кредитной карте в сторонних службах, используемых Сайтом (YooKassa). </p>
            
            <p>Если Сайт не получает оплату от поставщика вашей кредитной карты, вы соглашаетесь выплатить все суммы, причитающиеся по требованию, и признаете, что Сайт может приостановить ваш доступ к Услугам в случае, если такие суммы не будут своевременно выплачены.</p>
                
            <p>Все покупки (платежи) являются окончательными, и Сайт не будет возвращать средства, включая возврат предварительно оплаченных ежемесячных платежей.</p>
            
            <p>Все цены указаны в российских рублях, если не указано иное.</p>

            <el-divider/>
            <h3>7. Бесплатная пробная версия</h3>
            
            <p>Сайт может предлагать бесплатные пробные версии отдельных функций Услуг. По окончании бесплатного пробного периода ваша возможность доступа к некоторым или всем Услугам будет прекращена.</p>
            
            <p>Сайт оставляет за собой право определять, имеете ли вы право на бесплатную пробную версию, и прекращать любую бесплатную пробную версию без предварительного уведомления по любой причине или без таковой.</p>

            <el-divider/>
            <h3>8. Отказ от гарантий</h3>

            <p>Сайт, услуги, материалы сайта и все пользовательское содержимое предоставляются «как есть», без каких-либо гарантий.</p>
            
            <p>Администрация отказывается в максимальной степени, разрешенной законом, от всех явных, подразумеваемых и предусмотренных законом гарантий, включая, помимо прочего, гарантии коммерческой пригодности, пригодности для определенной цели и отсутствия нарушения прав собственности.</p> 
            
            <p>Администрация отказывается от любых гарантий в отношении точности, безопасности, надежности, своевременности, доступности, совместимости и функциональности сайта, услуг, материалов сайта и пользовательского контента.</p> 
            
            <p>Администрация отказывается от любых гарантий на услуги или товары, полученные или рекламируемые на сайте или полученные через любые ссылки, отображаемые на сайте или через услуги или любой пользовательский контент, а также на любую информацию или рекомендации, полученные через любые ссылки, отображаемые на сайте, через услуги или любой пользовательский контент.</p>
            
            <p>Вы соглашаетесь с тем, что вы пользуетесь сайтом, услугам, материалам сайта и всем пользовательским содержимым на свой страх и риск.</p>
            
            <p>Вы понимаете и соглашаетесь с тем, что вы загружаете или иным образом получаете материалы или данные посредством использования сайтом и/или услугами, включая, но не ограничиваясь любым пользовательским контентом, на ваше собственное усмотренение и риски.</p>
            
            <p>Администрация не гарантирует, что сайт, услуги, материалы сайта или любой пользовательский контент будет безошибочным, что доступ к ним будет бесперебойным, дефекты будут исправлены, данные не будет потеряны, или что сайт или сервер который делает такой контент доступным, не содержит вирусов или других вредных компонентов.</p> 
            
            <p>В таком случае вы принимаете на себя всю стоимость всех необходимых обслуживания, ремонта или исправления.</p>
            
            <p>Администрация не гарантирует и не делает никаких заявлений относительно использования или результатов использования сайта, услуг, материалов сайта или любого пользовательского контента. </p>
            
            <p>Настоящим вы безвозвратно отказываетесь от любых претензий к Администрации, ее аффилированным лицам, акционерам, директорам, должностным лицам, сотрудникам и агентам в отношении сайта, услуг, материалов сайта и пользовательского контента, а также любого содержимого, который вы предоставляете третьим лицам (включая кредитные карты и другая персональная информация).</p>

            <el-divider/>
            <h3>9. Условия действия Соглашения</h3>
            Данное Соглашение вступает в силу при любом использовании данного сайта.
            Соглашение перестает действовать при появлении его новой версии.
            Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
            Администрация не оповещает пользователей об изменении в Соглашении.

            <el-divider/>
            Дата обновления 12 ноября 2022
        </div>
        </div>
    
    </div>
    
</template>
<style scoped>
.li {
    margin: 12px 0px;
}
</style>