<template style="margin-top: -10px;">
  <div>

    <div class="secondary-gradient">
      <el-row class="center landing-container">
      
        <el-col :span="12" class="landing-heading" style="width:1250px;">
          <h1 class="landing-title landing-title-start" style="margin-top:-20px;" v-html="$t('home.heartbit_landing_title')"></h1>
          <p  style="font-size: 18px;">{{$t('home.heartbit_landing_subtitle')}}</p>
          <!-- <h3>of your business, competitors, shop</h3> -->

          <el-button type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="ok-hand.png"/></el-button>
        </el-col>

        <el-col :span="12" style="text-align: center;display: flex;">
          <img style="margin:auto; width: 100%; max-width: 500px;" src="f39fd5fc-3dd5-4faa-a8fd-d0ccf6a88cc2.png"/>
        </el-col>

      </el-row>
    </div>

    <div>
      <el-row class="center landing-container">

        <el-col :span="12" style="text-align: center;display: flex;">
          <img style="margin:auto; width: 100%; max-width: 460px;" src="2814bcbd-5b89-4ee2-a36f-6cc58eec6b19.png"/>
        </el-col>
      
        <el-col :span="12" class="landing-heading" style="width:1250px;">
          <h2>{{$t('home.get_alerts_when_we_detect_changes')}}</h2>
          <p class="label-text">{{$t('home.when_a_change_is_detected')}}</p>

          <el-button style="margin-top:24px;" type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="high-voltage.png"/></el-button>
        </el-col>

      </el-row>
    </div>

    <el-divider/>

    <div>
      <el-row class="center landing-container">

        <el-col :span="12" class="landing-heading" style="width:1250px;">
          <h2>{{$t('home.various_user_cases')}}</h2>
          <p class="label-text">{{$t('home.we_detect_visual_changes')}}</p>

          <el-button style="margin-top:24px;" type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="raised-hand.png"/></el-button>
        </el-col>

        <el-col :span="12" style="text-align: center;display: flex;">
          <img style="margin:auto; width: 100%; max-width: 460px;" src="308d10ff-498a-4d35-9d26-52ec02fcd30f.png" />
        </el-col>

      </el-row>
    </div>

    <el-divider/>

    <div>
      <el-row class="center landing-container">

        <el-col :span="12" style="text-align: center;display: flex;">
          <img style="margin:auto;width: 100%; max-width: 250px;" src="e75599e3-ada8-49a4-a041-62c65f9fc495.png"/>
        </el-col>

        <el-col :span="12" class="landing-heading" style="width:1250px;">
          <h2>{{$t('home.easy_to_use')}}</h2>
          <p class="label-text">{{$t('home.just_enter_the_page_you_want')}}</p>

          <el-button style="margin-top:24px;" type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="thumbs-up.png"/></el-button>
        </el-col>

      </el-row>
    </div>


    <!-- <div>

      <el-row class="center landing-container">

        <el-col :span="12">

          <el-row>
            <div style="display: flex;">
              <img style="width:24px;margin: auto;" src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/325/shopping-cart_1f6d2.png" />
            </div>
            <div>
              <h4>Back in stock alerts</h4>
              <p class="label-text">Be the first to know when products you’re after are back in stock.</p>
            </div>
          </el-row>
          
          
        </el-col>

        <el-col :span="12">
          
        </el-col>

      </el-row>

    </div> -->

    <div class="secondary-gradient">
      <el-row class="center landing-container">

        <el-col :span="12" class="landing-heading" style="width:1250px;">
          <h2 v-html="$t('home.get_alerts_everywhere')"></h2>
          <p class="label-text" style="margin-bottom:32px;">
            {{$t('home.choose_way_to_get_alerts')}}<br/>({{$t('home.coming_soon')}} <img style="width:20px;margin-bottom: -4px;" src="grinning-face.png"/>)
              <!-- Download for free for 
              <el-link target="_blank" :underline="false" class="color-secondary clickable dont-break-out" href="https://apps.apple.com/en/app/">iOS</el-link>
              and 
              <el-link target="_blank" :underline="false" class="color-secondary clickable dont-break-out" href="https://apps.apple.com/en/app/">Android</el-link>. -->
          </p>

          
          <el-button type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="moai.png"/></el-button>
          
          <!-- <br/>
          <br/>
          <br/>
          
          <a href="https://apps.apple.com/en/app/"><img style="height:46px;" src="app_store_badge.svg"/></a>
          <a href="https://play.google.com/store/apps/details?id=" style="margin-left:12px;"><img style="height:46px;" src="google_play_badge.svg"/></a> -->

        </el-col>

        <el-col :span="12" style="text-align: center;display: flex;">
          <img style="margin:auto;width: 100%; max-width: 260px;" src="e96ed93f-caac-4925-ae73-0a543c3f8809.png"/>
        </el-col>
        
      </el-row>
    </div>


    <div style="padding:32px">
      <div class="center landing-container" style="text-align: center; max-width: 600px;">

        <h2>{{$t('home.welcome_to_alplha_testing')}}</h2>

        <p class="label-text" style="margin-bottom:32px;">
          {{$t('home.we_have_launched_our_service')}}
          <br/>
          <el-link target="_blank" :underline="false" class="color-primary clickable dont-break-out" href="mailto:support@heart-bit.online">support@heart-bit.online</el-link>
        </p>

        <el-button style="margin-bottom: 38px;" type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="love-you-gesture.png"/></el-button>

      </div>
    </div>
    
      <div class="footer-container">
        
        <div style="padding-right:42px; max-width: 210px;">
          <h4>HeartBit</h4>
          <p class="label-secondary" style="color: #2c3e50;">{{$t('home.heartbit_footer_text')}}</p>
        </div>

        <div style="padding-right:42px; width: 210px;">
          <h4>{{$t('footer.company')}}</h4>
          <p>
            <!-- <el-link :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="/blog">{{$t('footer.blog')}}</el-link>
            <br/> -->
            <el-link :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="/info">{{$t('footer.info')}}</el-link>
            <br/>
            <el-link :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="/privacy">{{$t('footer.privacy')}}</el-link>
            <br/>
            <el-link :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="/terms">{{$t('footer.terms')}}</el-link>
            <!-- <br/>
            <el-link :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="/blog">Blog</el-link> -->
          </p>
        </div>

        <div style="padding-right:42px; width: 210px;">
          <h4>{{$t('footer.contacts')}}</h4>
          <p>
            <el-link target="_blank" :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="https://t.me/heartbit_support">Telegram</el-link>
            <br/>
            <el-link target="_blank" :underline="false" class="color-secondary clickable dont-break-out label-secondary" href="https://wa.me/375259723759">WhatsApp</el-link>
            <br/>
            <el-link :underline="false" class="clickable dont-break-out label-secondary" href="mailto:support@heart-bit.online">support@heart-bit.online</el-link>
          </p>
        </div>

      </div>


    <!-- <el-row class="center container-small" style="margin-top: 80px; text-align: start;">
        <div>
          <h2 style="margin:0; display: flex;"><span class="dot">1</span> <span style="margin-left:12px;">Quick start</span></h2>
          <p>Try monitoring for free</p>
        </div>
    </el-row> -->

    <!-- <JobCreate style="margin-bottom: 100px;"/> -->
    

    <!-- <div style="max-width: 200px;">
      <h2>3.1k checks</h2>
      <text>in last day</text>
    </div> -->

    <!-- <div style="max-width: 200px;">
      <h2>3.1k checks</h2>
      <text>in last day</text>
    </div> -->

    

  </div>
</template>

<script>
import { heartApp } from "@/main.js";
// import JobCreate from "./JobCreate.vue";

export default {
    name: "HomePage",
    data() {
        return {
            app: heartApp,
        };
    },
    methods: {},
    // components: { JobCreate }
};
</script>