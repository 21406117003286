<template>
  <div id="app">

    <div v-if="app.getAppBarIcon(this.$route) == 'menu'" style="padding: 12px 12px; vertical-align: middle" v-on:click="drawer=true" >
      <img src="ic_menu.svg" style="width:24px; height:24px; margin-left:8px; vertical-align: middle;"/>
    </div>

    <div v-if="app.getAppBarIcon(this.$route) == 'back'" style="display: flex; padding: 14px 12px; vertical-align: middle;" v-on:click="$router.back()">
      <img src="ios-arrow-back.svg" style="width: 24px; height: 24px; vertical-align: middle;"/>
      <p class="noselect" style="margin: auto 0px auto 4px; font-weight: 700; font-size: 17px; color: var(--blue)">{{ $t('common.back') }}</p>
    </div>

    <!-- <el-divider style="margin:0"/> -->
    
    <el-header v-if="app.isWeb()" style="overflow-x: scroll;">
      <div class="el-header-container">

        <el-menu mode="horizontal" :ellipsis="false" :router="true" style="height: var(--header-menu-height); border-right: none;">
          
          <el-menu-item index="1" route="/"><text style="font-size: 1.2rem; font-weight: 700">HeartBit</text></el-menu-item>
          <div style="flex-grow: 1"/>

          <!-- <el-menu-item index="2" class="semi-bold" route="blog">{{$t('common.blog')}}</el-menu-item> -->
          <el-button type="primary" style="margin: auto 12px;" v-if="app.isAuthorized" v-on:click="this.$router.push({ path: 'pricing' })">{{$t('common.upgrade_plan')}}</el-button>
          <el-menu-item index="3" class="semi-bold" route="pricing">{{$t('common.pricing')}}</el-menu-item>
          <el-menu-item index="4" class="semi-bold" route="account" v-if="app.isAuthorized">{{$t('common.account')}}</el-menu-item>
          <el-menu-item index="5" class="semi-bold" route="dashboard" v-if="app.isAuthorized" style="color: var(--el-menu-hover-text-color)">{{$t('common.dashboard')}}</el-menu-item>
          <el-menu-item index="6" class="bold" route="login" v-if="!app.isAuthorized" style="color: var(--el-menu-hover-text-color)">{{$t('common.login')}}</el-menu-item>

        </el-menu>

      </div>
    </el-header>

    <el-main>
      <router-view></router-view>
    </el-main>

    <el-drawer v-if="app.isMobile()" title="HeartBit" v-model="drawer" direction="ltr" custom-class="drawer">

      <template #header="{ titleId, titleClass }">
        <text :id="titleId" :class="titleClass" style="color:var(--el-color-primary); font-size: 1.2rem; font-weight: 800; font-size: 21px;">HeartBit</text>
      </template>

      <el-menu mode="vertical" :ellipsis="false" :router="true" style="height: var(--header-menu-height); border-right: none;">

        <el-button type="primary" style="margin: 0px 12px;" v-if="app.isAuthorized" v-on:click="drawer = false; this.$router.push({ path: 'pricing' })">{{$t('common.upgrade_plan')}}</el-button>
        <el-divider style="margin-bottom: 0px;" />
          
        <el-menu-item index="1" class="semi-bold noselect" v-on:click="drawer = false" route="dashboard" style="font-weight: 700; font-size: 16px;"><el-icon style="margin-right:12px;"><MagicStick /></el-icon>{{$t('common.dashboard')}}</el-menu-item>
        <el-menu-item index="2" class="semi-bold noselect" v-on:click="drawer = false" route="pricing" style="font-weight: 700; font-size: 16px;"><el-icon style="margin-right:12px;"><Star /></el-icon>{{$t('common.pricing')}}</el-menu-item>
        <el-menu-item index="3" class="semi-bold noselect" v-on:click="drawer = false" route="account" style="font-weight: 700; font-size: 16px;"><el-icon style="margin-right:12px;"><User /></el-icon>{{$t('common.account')}}</el-menu-item>
       
      </el-menu>

    </el-drawer>

  </div>
</template>

<script>
import { heartApp } from "@/main.js";

export default {
    name: "App",
    data() {
        return {
            app: heartApp,
            drawer: false,
        };
    },
    methods: {
        performLogout() {
            heartApp.logout();
        },
    },
  }

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&family=Rubik:wght@500;600;700&display=swap');
</style>

<style>
:root {
  --el-font-family: 'Nunito Sans', sans-serif;
  --blue: #1e90ff;
  --el-color-primary: #ff1744;
  --el-color-primary-light-9: #ff174411;
  --el-color-primary-light-5: #ff616f;
  --el-color-primary-light-3: #ff5252;
  --el-color-primary-dark-2: #c50e29;
  --el-font-size-base: 15px;
  --el-border-radius-base: 9px;
  --el-component-size: 37px;
  --el-card-border-radius: 14px;
  --header-menu-height: 60px;
  /* --el-border-width: 2px; */
}
html,
body {
  font-family: "Nunito Sans", sans-serif !important;
  margin: 0;
}
#app {
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.accent-font {
  font-family: 'Rubik', sans-serif !important;
}
.accent-gradient {
  background: linear-gradient(to right, var(--el-color-primary), var(--el-color-primary-light-5));
}
.secondary-gradient {
  background: linear-gradient(to right, #c6e2ff, white);
}
h1, h2 {
  font-family: 'Rubik', sans-serif !important;
  font-weight: 600;
}
.el-switch__label {
  color:#909399;
}

.primary-color {
  color: var(--el-color-primary);
}

.el-header {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.el-header::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.warning {
  background-color: var(--el-color-primary-light-9);
  border-radius: 0;
  padding: 0px 24px 20px 24px;
  border-left: 4px solid var(--el-color-primary-light-5) !important;
}
.dot {
  height: 20px;
  padding: 4px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  width: 20px;
  color: white;
  background-color: var(--el-color-primary);
  border-radius: 50%;
  display: inline-block;
  font-size: 20px;
}
.clickable {
  cursor: pointer;
}
.clickable:hover  {
  background: var(--el-color-primary-light-9);
  color: red !important;
}
.landing-container {
  max-width: 1200px;
  overflow: hidden;
}
.landing-heading {
  padding: 132px 56px;
  text-align: start;
}
.el-main {
  padding: 0;
}
.container {
  max-width: 260px;
}
.container-large {
  max-width: 900px;
  width: 100%;
}
.container-medium {
  max-width: 550px;
  width: 100%;
}
.container-small {
  max-width: 380px;
  width: 100%;
}
.center {
  margin: auto;
}
.left {
  text-align: start;
}
.full-width {
  width: 100%;
}
input {
  font-family: "Nunito Sans", sans-serif !important;
}
.el-card {
  --el-card-border-radius: 14px;
  border: 2px solid var(--el-card-border-color);
}
.el-card[selected=true] {
  border: 2px solid var(--el-color-primary);
}
.el-card:hover {
  border: 2px solid transparent;
}
/* .el-divider--horizontal {
  border-top: 2px var(--el-border-color) var(--el-border-style);
} */
.el-button {
  font-family: 'Rubik', sans-serif !important;
  font-weight: 500;
  height: 36px;
  font-size: 15px;
}
.el-row {
  margin-bottom: 16px;
}
.semi-bold {
  font-weight: 600;
}
.bold, .bold > * {
  font-weight: 800;
}
.no-underline {
  text-decoration: none;
}
.color-primary {
  color: var(--el-color-primary);
}
.color-secondary {
  color: #909399;
}
.color-thirtly {
  color: #bfbfc0;
}
.text-small {
  font-size: 14.5px;
  margin-bottom: 6px;
}
.label-secondary {
  color: #909399;
  font-size: 14.5px;
  margin-bottom: 0.5em;
}
.label {
  margin: 0;
}
.label-text {
  line-height: 1.7em;
}
.center-vertical {
  margin: auto 0;
}
.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}
.placeholder {
  width: 80%;
  max-width: 1280px;
  display: flex;
}
.shadow {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.flex-row {
  display: flex; 
  flex-direction: row; 
  flex-wrap: wrap;
}

.flex-row-item {
  margin-right:12px; 
  margin-top:12px;
}

.button-link,
.router-link-active,
.router-link-exact-active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.button-icon {
  width:20px; 
  margin-left:12px;
}

.padding {
  padding: 18px 24px;
}

.noselect {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.footer-container {
  background: #fafafa; 
  padding:32px 64px; 
  display:flex;
  flex-wrap:wrap;
}

.landing-title-start {
  text-align: start !important;
}

.landing-title {
  font-size: 48px; 
  margin: 0; 
  line-height: 1.23;
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-favicon > img {
  vertical-align: baseline !important;
}

button, .button {
  -webkit-tap-highlight-color: transparent;
}

button:active, .button:active {
  transform: scale(0.97);
}

.hide-if-not-mobile {
  display: none !important;
}

.dashboard-menu {
  max-width: 650px; 
  min-width:450px; 
  background: white; 
  text-align: start; 
  box-shadow: 2px 1px 33px -19px rgb(130 148 161 / 40%);
}

.dashboard-main {
  flex-grow: 1; 
  height: calc(100vh - var(--header-menu-height)); 
  overflow: hidden; 
  min-width: 600px;
}

.drawer {
  width: 70% !important;
  max-width: 400px;
}

.drawer > .el-drawer__body {
  padding: 0;
}

@media only screen and (max-width : 860px) {
  .hide-if-mobile {
    display: none !important;
  }
  .hide-if-not-mobile {
    display: initial !important;
  }
  .el-row {
    display: block;
  }
  .el-col {
    width: 100% !important;
    max-width: 100% !important;
  }
  .landing-container{
    width: 100% !important;
    max-width: 650px !important;
  }
  .landing-heading {
    padding: 16px 26px 24px 26px;
    text-align: center;
  }
  .landing-title-start {
    text-align: center !important;
    margin-top: 42px !important;
  }
}

@media only screen and (max-width : 1010px) {
  .landing-title {
    font-size: 42px; 
  }
  .dashboard-menu {
    min-width: 100%;
  }
  .dashboard-main {
    min-width: 100%;
  }
}

@media only screen and (max-width : 440px) {
  .landing-title {
    font-size: 38px; 
  }
  .drawer {
    min-width: 100%;
  }
}

@media only screen and (max-width : 400px) {
  .landing-title {
    font-size: 32px; 
  }
}
@media only screen and (max-width : 340px) {
  .landing-title {
    font-size: 26px; 
  }
}
</style>
