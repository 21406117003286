<template>
  <div v-loading="loading" class="padding">

    <!-- <el-row class="center container-medium" style="margin-top: 80px; text-align: start;">
        <div>
          <h2 style="margin:0; display: flex;"><span class="dot">1</span> <span style="margin-left:12px;">Quick start</span></h2>
          <p>Try monitoring for free</p>
        </div>
    </el-row> -->

    <!-- <cropper class="cropper" :src="imgUrl"></cropper> -->

    <el-row class="center container-large" style="text-align: start;">
      <div>
        <h2 style="margin:0; display: flex;">{{jobId ? $t('new_job.edit') : $t('new_job.title')}}</h2>
      </div>
    </el-row>

    <!-- <el-row v-if="!app.account.canCreateJob" class="center container-large">
      <el-card style="border-radius: var(--el-card-border-radius); border: 1px solid var(--el-card-border-color);">
        </el-card>
    </el-row> -->

    <el-row>
      <div class="center container-large">
        <el-row>
          <p class="label-secondary">{{$t('new_job.page_address')}}</p>
          
          <el-input v-model="url" :placeholder="$t('new_job.url_placeholder')">
            <template #prefix>
              <el-icon class="el-input__icon"><Link /></el-icon>
            </template>
          </el-input>
        </el-row>

        <el-row v-if="app.account && app.account?.canCreateJob">
          <div style="width:100%; display:flex;">
            <el-button v-if="!image" type="primary" v-on:click="fetchUrl" plain>{{$t('common.continue')}}</el-button>
            <el-switch v-model="advanced" :active-text="$t('new_job.advanced_settings')" style="margin-left: auto; margin-top: auto;" />
          </div>
        </el-row>

        <el-row v-if="app.account && !app.account?.canCreateJob">
          {{$t('new_job.you_have_maximum_jobs')}}
        </el-row>

        <el-row v-if="app.account && !app.account?.canCreateJob">
          <el-button type="primary" v-if="app.isAuthorized" v-on:click="this.$router.push({ path: 'pricing' })">{{$t('common.upgrade_plan')}} <img class="button-icon" src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/325/high-voltage_26a1.png"/></el-button>
        </el-row>

        <div v-show="advanced">

          <el-divider border-style="dashed "/>

          <p class="label-secondary">{{$t('new_job.wait_after_load')}}</p>

          <el-row class="container-small">
            <el-col :span="18">
              <el-select
                class="p-2 full-width"
                v-model="waitAfterLoad">
                <el-option
                  v-for="item in delays"
                  :key="item.value"
                  :label="item.label"
                  :value="item">
                  <span style="float: left">{{item.label}}</span>
                  <el-icon v-if="!item.enabled" style="float: right; height: 100%; margin-left: 8px; color: var(--el-color-primary)"><Lock/></el-icon>
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <p class="label-secondary">{{$t('new_job.screen_size')}}</p>

          <el-row class="container-small">
            <el-col :span="18">
              <el-select
                class="p-2 full-width"
                v-model="screen"
                value-key="label">
                <el-option
                  v-for="item in screens"
                  :key="item.label"
                  :label="item.label"
                  :value="item">
                  <span style="float: left">{{item.label}}</span>
                  <el-icon v-if="!item.enabled" style="float: right; height: 100%; margin-left: 8px; color: var(--el-color-primary)"><Lock/></el-icon>
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-checkbox v-model="disableJavaScript" :label="$t('new_job.disable_javascript')" size="large" />

        </div>

      </div>
    </el-row>

    <div v-if="image" style="background: #fafafa; padding:32px 0px; margin-bottom: 62px; text-align: center;">
      
      <div style="margin-bottom: 18px; margin-top: -12px; padding: 0px 12px;">
        <p class="container-large center label-secondary">
          {{ $t('new_job.select_area_1') }}{{ $t('new_job.select_area_2') }}
          <!-- <a :underline="false" class="clickable no-underline dont-break-out color-primary" @click="selectAllPage">{{ $t('new_job.select_area_2') }}</a> -->
        </p>
      </div>

      <div class="center shadow" style="margin: auto; display: inline-block; max-width: 100%;">
        <cropper v-on="cropper" class="cropper" :src="image" moveImage="false" :resizeImage="resizeImage" :defaultSize="defaultSize" :defaultPosition="defaultPosition" @change="change"></cropper>
      </div>
    
      <div class="container-large center" style="padding-top:32px;">

        <el-row class="container-large" style="margin-top: 20px; text-align: start;">
          <div>
            <h2 style="margin:0;font-weight: 800;display: flex;">{{$t('new_job.configure')}}</h2>
            <p>{{$t('new_job.select_checks_frequency')}}</p>
          </div>
        </el-row>

        <el-row class="container-medium">
          <p class="label-secondary">{{$t('new_job.job_name')}}</p>
          <el-input v-model="jobName" :placeholder="$t('new_job.job_name_placeholder')"/>
        </el-row>

        <el-row class="container-small">

            <el-col :span="18">
              <el-select
                class="p-2 full-width"
                v-model="cron"
                :placeholder="$t('new_job.check_frequency')">
                <el-option
                  v-for="item in app.account.tariff.parameters.cron"
                  :key="item.value"
                  :label="$t(item.label)"
                  :value="item">
                  <span style="float: left">{{ $t(item.label) }}</span>
                  <el-icon v-if="!item.enabled" style="float: right; height: 100%; margin-left: 8px; color: var(--el-color-primary)"><Lock/></el-icon>
                </el-option>
              </el-select>
            </el-col>

        </el-row>

        <el-row v-show="canCreateJob" class="container-small"> 
          <el-button type="primary" v-on:click="startMonitoring"><el-icon class="el-icon--left"><Select /></el-icon> {{jobId ? $t('new_job.save_changes') : $t('new_job.start_monitoring')}}</el-button>
        </el-row>

      </div>
    </div>

    <el-row v-show="image && shouldUpgradeTariff" class="container-large center warning" style="margin-top:12px;"> 
      <div>
        <p style="margin-bottom:12px;" class="semi-bold">{{$t('new_job.parameter_not_supported')}}</p>
        <el-button type="primary" plain v-on:click="this.$router.push({ path: 'pricing' })">{{$t('common.upgrade_plan')}}</el-button>
      </div>
    </el-row>

  </div>
</template>

<style scoped>

</style>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { heartApp } from "@/main.js";
import { performCatchable } from "@/app/helpers";

export default {
  name: "JobCreate",
  onlyAuthorized: true,
  components: { Cropper },
  data() {
    return {
      defaultSize: {
        width: 350,
        height: 200
      },
      defaultPosition: {
        left: 100,
        top: 100
      },
      resizeImage: {
        wheel: 0
      },
      app: heartApp,
      sitekey: '6Lf9qVshAAAAALSplDBIpotfs7tikXLClg0m72qD',
      showCaptcha: false,
      jobId: null,
      loading: false,
      url: null,
      jobName: null,
      image: null,
      cron: null,
      rectangle: null,
      subscriber: null,
      advanced: false,
      cropper: true,
      disableJavaScript: false,
      delays: [
        { label: "5 сек", value: "00:00:05", enabled: true },
        { label: "10 сек", value: "00:00:10", enabled: true },
        { label: "20 сек", value: "00:00:20", enabled: false },
        { label: "30 сек", value: "00:00:30", enabled: false }
      ],
      waitAfterLoad: null,
      screens: [
        { label: "Desktop (1440x1080)", screenWidth:1440, screenHeight:1080, deviceScaleFactor:1, isMobile:false, hasTouch: false, enabled: true },
        { label: "Desktop (1280x768)", screenWidth:1280, screenHeight:768, deviceScaleFactor:1, isMobile:false, hasTouch: false, enabled: true }, 
        { label: "Desktop (1920x1080)", screenWidth:1920, screenHeight:1080, deviceScaleFactor:1, isMobile:false, hasTouch: false, enabled: false }, 
        { label: "Mobile (iPhone 11)", screenWidth:414, screenHeight:828, deviceScaleFactor:2, isMobile:true, hasTouch: true, enabled: false },
        { label: "Mobile (iPhone SE)", screenWidth:320, screenHeight:568, deviceScaleFactor:2, isMobile:true, hasTouch: true, enabled: false }
      ],
      screen: null,
    };
  },

  watch: {
    waitAfterLoad() {
      if (this.image) {
        this.fetchUrl();
      }
    },
    screen() {
      if (this.image) {
        this.fetchUrl();
      }
    },
    disableJavaScript() {
      if (this.image) {
        this.fetchUrl();
      }
    }
  },

  computed: {
    shouldUpgradeTariff: function () {
      return this.cron?.enabled === false 
        || this.screen?.enabled === false 
        || this.waitAfterLoad?.enabled === false;
    },
    canCreateJob: function () {
      return this.url
        && this.image
        && this.cron?.enabled === true
        && this.screen?.enabled === true
        && this.waitAfterLoad?.enabled === true;
    }
  },

  async beforeMount() {
    if (!this.app.account) {
      this.loading = true;
    }

    await performCatchable(async () => {
      await this.app.refreshAccount();
    });

    this.loading = false;

    console.log(this.$route.hash)
    this.jobId = this.$route.hash.replace('#', '');   

    if (this.jobId) {
      this.loading = true;

      await performCatchable(async () => {
        var job = await this.app.getJob(this.jobId);

        this.url = job.url;
        this.jobName = job.name;
        this.waitAfterLoad = this.delays.find(x => x.value == job.parameters?.waitAfterLoad) || this.delays[0];
        this.screen = this.screens.find(x => x.screenWidth == job.parameters?.screenWidth && x.screenHeight == job.parameters?.screenHeight) || this.screens[0];
        this.disableJavaScript = job.parameters?.disableJavaScript;
        this.cron = this.app.account.tariff.parameters.cron.find(x => x.value == job.cron) || this.app.account.tariff.parameters.cron[0];

        if (job.parameters?.rectangles && job.parameters?.rectangles?.length > 0) {
          this.defaultSize = {
            width: job.parameters.rectangles[0].width,
            height: job.parameters.rectangles[0].height
          };
          this.defaultPosition = {
            left: job.parameters.rectangles[0].left,
            top: job.parameters.rectangles[0].top
          };
        }
        else {
          this.defaultSize = {
            width: Number.MAX_SAFE_INTEGER,
            height: Number.MAX_SAFE_INTEGER
          };
          this.defaultPosition = {
            left: 0,
            top: 0
          };
        }

        this.advanced = this.waitAfterLoad != this.delays[0]
          || this.screen != this.screens[0]
          || this.disableJavaScript
          || this.cron != this.app.account.tariff.parameters.cron[0];

        await this.fetchUrl();
      });
    }

    this.loading = false;
  },

  created() {
    this.waitAfterLoad = this.delays[0];
    this.screen = this.screens[0];
    this.app.created(this);
  },
  
  methods: {
    change({coordinates, canvas}) {
      console.log(coordinates, canvas);
      this.rectangle = coordinates;
    },

    selectAllPage() {
      this.cropper = false;

      this.defaultSize = {
        width: Number.MAX_SAFE_INTEGER,
        height: Number.MAX_SAFE_INTEGER
      };
      this.defaultPosition = {
        left: 0,
        top: 0
      };

      this.cropper = true;
    },

    getJobParameters() {
      return { 
        waitAfterLoad: this.waitAfterLoad?.value, 
        disableJavaScript: this.disableJavaScript, 
        screenWidth: this.screen?.screenWidth, 
        screenHeight: this.screen?.screenHeight,
        deviceScaleFactor: this.screen?.deviceScaleFactor,
        isMobile: this.screen?.isMobile,
        hasTouch: this.screen?.hasTouch,
        rectangles: this.rectangle ? [this.rectangle] : null
      }
    },

    async fetchUrl() {
      if (!this.url) {
        return;
      }

      this.loading = true;
      this.image = null;

      await performCatchable(async () => {
        var response = await this.app.fetchUrl(this.url, this.getJobParameters());
        this.image = response.image;
        this.jobName = response.pageTitle;
      });

      this.loading = false;
    },

    async startMonitoring() {   
      if (!this.canCreateJob) {
        return;
      }

      this.loading = true;
      
      await performCatchable(async () => {
        await this.app.createJob(this.jobId, this.url, this.jobName, this.cron.value, this.getJobParameters());
        this.$router.push('/dashboard');
      });

      this.loading = false;
    },
  },
};
</script>