<template>
  <div style="display: flex;">
    <!-- <div style="height: 90vh;">
      <el-menu default-active="2" style="height: 100%;">
        <el-menu-item index="2">
          <el-icon><CreditCard /></el-icon>
          <span>{{$t('account.menu.profile_and_billing')}}</span>
        </el-menu-item>
        <el-menu-item index="4">
          <el-icon><ChatRound /></el-icon>
          <span>{{$t('account.menu.support')}}</span>
        </el-menu-item>
      </el-menu>
    </div> -->

    <div v-loading="accountLoading" style="flex-grow: 1;">

      <!-- <h1 style="text-align: start; margin:32px;">Profile</h1> -->

        <div class="secondary-gradient" style="padding: 32px;">

          <div v-if="app.account">

            <el-row>
              <div>
                <p>{{$t('account.your_balance')}}</p>
                <h1 style="line-height: 0;">{{app.account.balance}}₽</h1>
              </div>
            </el-row>
            
            <div class="flex-row">
              <div>
                <el-button class="flex-row-item" type="primary" v-on:click="$refs.rechargeBalanceView.show()">{{$t('common.recharge')}}</el-button>
              </div>
              <div>
                <el-button class="flex-row-item" type="primary" plain v-on:click="this.$router.push({ path: 'pricing' })">{{$t('common.upgrade_plan')}}</el-button>
              </div>
            </div>

            <p style="margin-top: 32px;">
              <span v-html="$t('account.your_plan_is', { value: $t(app.account.tariff.name) })"></span>
              {{$t('account.you_pay_per_month', { value: app.account.tariff.price })}}
            </p>

          </div>

        </div>

        <!-- <el-divider /> -->

        <div v-if="app.account" style="margin:32px;">

          <!-- <p style="text-align: start;">Profile information</p> -->

          

          <el-row class="container-small">
            <p class="label-secondary">{{$t('account.name')}}</p>
            <el-input v-model="name" :placeholder="$t('account.name_placeholder')" />
          </el-row>

          <!-- <el-divider /> -->

          <el-row class="container-small">
            <p class="label-secondary">{{$t('account.email')}}</p>
            <el-input v-model="email" placeholder="hello@world.ru" />
          </el-row>

          <el-row>
            <p class="text-small color-secondary" v-html="$t('account.if_you_change_email')"></p>
          </el-row>

          <el-row>
            <el-button type="primary" plain @click="saveChanges()" :loading="saveChangesLoading">{{$t('common.save')}}</el-button>
          </el-row>

          </div>

          <el-divider />

          <div style="margin:32px;" class="container">
            
            <el-row>
              <el-button text @click="logout()" style="padding: 0;">{{$t('common.logout')}}<el-icon class="el-icon--right"><Right /></el-icon></el-button>
            </el-row>

          </div>

        <RechargeBalanceView ref="rechargeBalanceView"/>
    
    </div>

  </div>
</template>

<!-- <div style="display: flex; overflow: hidden; height: calc(100vh - 65px);">
    
    <div style="background: #e3f2fd; width: 100%;">
      
    </div>
    
    <div class="container-medium" style="display:flex;">

      <div class="container-medium" style="padding:0px 62px; text-align: start; margin-top: 15%;">

        <h1 class="left" style="margin-top:0;">Recharge account</h1>

        <div id="payment-form"></div>

      </div>
    </div>

  </div> 

</template> -->

<style scoped>
.primary-color {
  color: var(--el-color-primary);
}
</style>

<script>
import RechargeBalanceView from "@/components/RechargeBalanceView.vue";
import { performCatchable, wait } from "@/app/helpers"
import { heartApp } from "@/main.js";

export default {
  name: "PricingPage",
  onlyAuthorized: true,
  components: {
    RechargeBalanceView
  },
  data() {
    return {
      name: null,
      email: null,
      accountLoading: false,
      saveChangesLoading: false,
      app: heartApp,
    };
  },

  created() {
    this.app.created(this);
  },

  beforeMount() {
    this.refresh();
  },

  methods: {
    async refresh() {
      this.accountLoading = true;

      if (new URL(location).searchParams.get('payment')) {
          await wait(5000);
      }

      await performCatchable(async () => {
        await this.app.refreshAccount();
        this.name = this.app.account.name;
        this.email = this.app.account.email;
      });
      
      this.accountLoading = false;
    },

    async saveChanges() {
      this.saveChangesLoading = true;

      await performCatchable(async () => {
        await this.app.updateAccount(this.name, this.email);
      });

      this.saveChangesLoading = false;
    },

    async logout() {
      await performCatchable(async () => {
        await this.app.logout();
      });

    },
  }
};
</script>