<template>
    <el-drawer
          v-model="drawer"
          :size="app.screenWidth >= 1010 ? '470px': '100%'"
          direction="ltr">

          <div>

            <h1 style="text-align: start;">{{$t('common.recharge_balance')}}</h1>

            <el-row class="container" v-if="!paymentCreated">
              <div>
                <p class="label-secondary">{{$t('common.amount_with_currency')}}</p>
                <el-input-number v-model="amount" :precision="0" :step="1" :max="10000" :min="1" />
              </div>
            </el-row>

            <el-row v-if="!paymentCreated">
              <el-checkbox v-model="argeement" :label="$t('payment.agree_with_paymentterms')" size="large" />
            </el-row>

            <el-row v-if="!paymentCreated">
              <el-button type="primary" @click="performPayment" :loading="rechargeLoading" :disabled="!argeement">{{$t('common.continue')}}</el-button>
            </el-row>

            <div id="payment-form"></div>

          </div>

        </el-drawer>
</template>


<script>
import { performCatchable, wait } from "@/app/helpers"
import { heartApp } from "@/main.js";

export default {
  name: "RechargeBalanceView",
  data() {
    return {
      paymentCreated: false,
      drawer: false,
      amount: 200,
      argeement: true,
      rechargeLoading: false,
      app: heartApp,
    };
  },

  created() {
    this.app.created(this);
  },

  beforeMount() {
    this.refresh();
  },

  methods: {
    show(amount) {
      // Recharge and pricing only on web
      if (this.app.isMobile()) {
        window.open(`https://heart-bit.online/account`, '_blank');
        return;
      }

      this.amount = amount ?? Math.max(199, this.app.account.tariff.price);
      this.drawer = true;
    },

    async refresh() {
      if (new URL(location).searchParams.get('payment')) {
          await wait(5000);
      }

      await performCatchable(async () => {
        await this.app.refreshAccount();
      });
    },

    async performPayment() {
      if (!this.argeement) {
        return;
      }

      this.rechargeLoading = true;

      await performCatchable(async () => {
        await this.app.performPayment(this.amount, 'payment-form');
        this.paymentCreated = true;
      });

      this.rechargeLoading = false;
    },
  }
};
</script>