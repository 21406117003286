import axios from 'axios';

export const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async config => {
            config.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

            const accessToken = localStorage.getItem('token');

            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }

            return config;
        },
        error => {
            Promise.reject(error)
        });

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config;
        
        // TODO: status 401
        if (error.response.status === 0 && !originalRequest._retry) {
            console.log(error.response);
            originalRequest._retry = true;
            const accessToken = await axiosApiInstance.refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return axiosApiInstance(originalRequest);
        }
        return Promise.reject(error);
    });