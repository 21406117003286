<template>
  <div class="center">
    
    <div class="secondary-gradient">
      <el-row class="center" style="max-width: fit-content; padding: 100px 56px 190px; text-align: center;">  
        <div>
          <h1 class="landing-title" style="margin: 0; font-weight: 800; line-height: 1">
            {{$t('pricing.title')}}
          </h1>
          <p>{{$t('pricing.subtitle')}}</p>
        </div>
      </el-row>
    </div>

    <el-scrollbar style="margin-top: -150px;">
      <el-row class="container" :gutter="20">

        <el-col class="hide-if-mobile" :span="4" style="min-width: 300px; max-width: 300px;">
            <el-card class="tariff tariff-legend" style="background: transparent;">
              <div class="tariff-heading" style="background: none;">
                <p class="color-secondary">&nbsp;</p>
                <h1>&nbsp;</h1>
              </div>
              
              <p>{{$t('pricing.max_pages')}}</p> 
              <el-divider border-style="dashed" style="margin:0"/>

              <p>{{$t('pricing.max_frequency')}}</p>
              <el-divider border-style="dashed" style="margin:0"/>

              <p>{{$t('pricing.features')}}</p>
              <el-divider border-style="dashed" style="margin:0"/>

            </el-card>
        </el-col>

        <template v-for="tariff in tariffs" :key="tariff.id">
          <el-col :span="5" class="tariff-container">
            <el-card class="box-card tariff" shadow="hover" :selected="tariff.selected">
              <div class="tariff-heading">
                <p class="color-secondary" style="color:white;">{{$t(tariff.name)}}</p>
                <h1 style="color:white">{{$t(tariff.price_label)}}</h1>
              </div>

              <p><span class="label-secondary hide-if-not-mobile">{{$t('pricing.max_pages')}}<br/></span>{{$t(tariff.maxEndpoints)}}</p>
              <el-divider style="margin:0"/>

              <p class="primary-color"><span class="label-secondary hide-if-not-mobile">{{$t('pricing.max_frequency')}}<br/></span>{{$t(tariff.maxFrequency)}}</p>
              <el-divider style="margin:0" />

              <!-- <p style="margin-bottom: -14px;"><span class="label-secondary hide-if-not-mobile" style="margin-bottom:-12px;">{{$t('pricing.features')}}</span></p> -->
              <div v-html="$t(tariff.features)"></div>
              <el-divider style="margin-top:0"/>
              
              <el-button class="full-width" :text="tariff.buttonText" plain :bg="tariff.buttonText" :type="tariff.buttonType" v-on:click="tariff.click(tariff)">
                {{$t(tariff.button.key, { value: tariff.button.value })}}
              </el-button>
            </el-card>
          </el-col>
        </template>
      </el-row>
    </el-scrollbar>

    <RechargeBalanceView ref="rechargeBalanceView"/>
    
  </div>
</template>

<style scoped>

.container {
  margin:32px auto; 
  flex-wrap: nowrap;
  max-width: 100%;
  padding: 0px 16px;
}

.tariff-container {
  margin-bottom: 32px !important;
  min-width: 320px;
  max-width: 340px;
}

@media only screen and (max-width : 860px) {
  .el-row {
    display: block;
  }
  .el-col {
    width: 100% !important;
    max-width: 100% !important;
  }
  .landing-container{
    width: 100% !important;
    max-width: 650px !important;
  }
  .landing-heading {
    padding: 66px 56px;
    text-align: center;
  }
  .container {
    max-width:100%;
    min-width: 100%; 
    margin:32px auto !important; 
    flex-wrap: nowrap;
  }
  .tariff-container {
    max-width: 440px !important;
    margin: 0 auto;
  }
}

.tariff-heading {  
  background-color: var(--el-color-primary);
  margin: -20px -20px 0px -20px;
  padding: 20px;
  background-image: url('../../public/tariff-bg.png');
}

.tariff-heading > p, h1, h2, h3 {
  margin:0;
}
h1 {
  font-weight: 800;
}
.tariff * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tariff-legend {
  border:none; 
  box-shadow:none;
  text-align:end !important;  
}
.tariff-legend * {
  color:var(--el-text-color-secondary);
}
.tariff {
  text-align: start;
}
.el-divider {
  --el-border-color:var(--el-color-info-light-8);
}
</style>

<script>
import RechargeBalanceView from "@/components/RechargeBalanceView.vue";
import { performCatchable } from "@/app/helpers"
import { heartApp } from "@/main.js";

export default {
  name: "PricingPage",
  components: {
    RechargeBalanceView
  },
  data() {
    return {
      app: heartApp,
      tariffs: [
        {
          id: "free-2022",
          name: 'pricing.tariff_free',
          price: 0,
          price_label: 'pricing.tariff_free_price',
          maxEndpoints: 'pricing.tariff_free_pages',
          maxFrequency: 'pricing.once_day',
          features : 'pricing.tariff_free_features',
          button: { key: 'common.subscribe' },
          buttonType: "primary",
          buttonText: false,
          click: null,
        },
        {
          id: "small-2022",
          name: 'pricing.tariff_small',
          price: 199,
          price_label: 'pricing.tariff_small_price',
          maxEndpoints: 'pricing.tariff_small_pages',
          maxFrequency: 'pricing.once_hour',
          features : 'pricing.tariff_small_features',
          button: { key: 'common.subscribe' },
          buttonType: "primary",
          buttonText: false,
          click: null,
        },
        {
          id: "medium-2022",
          name: 'pricing.tariff_medium',
          price: 499,
          price_label: 'pricing.tariff_medium_price',
          maxEndpoints: 'pricing.tariff_medium_pages',
          maxFrequency: 'pricing.once_15_min',
          features : 'pricing.tariff_medium_features',
          button: { key: 'common.subscribe' },
          buttonType: "primary",
          buttonText: false,
          click: null,
        },
        {
          id: "large-2022",
          name: 'pricing.tariff_large',
          price: 1599,
          price_label: 'pricing.tariff_large_price',
          maxEndpoints: 'pricing.tariff_large_pages',
          maxFrequency: 'pricing.continuosly',
          features : 'pricing.tariff_large_features',
          button: { key: 'common.subscribe' },
          buttonType: "primary",
          buttonText: false,
          click: null,
        },
      ]
    };
  },
  beforeMount() {
    this.refresh();
  },

  methods: {
    async refresh() {
      await this.app.refreshAccount();

      this.tariffs.forEach((tariff) => {
        if (tariff.id === this.app.account.tariff.id) {
          tariff.button = { key: 'pricing.your_plan'};
          tariff.selected = true;
          tariff.buttonText = true;
          tariff.buttonType = 'primary';
          tariff.click = this.changeTariff;
        }
        else if (tariff.price > this.app.account.balance) {
          console.log(tariff.price)
          console.log(this.app.account.balance)
          tariff.button = { key: 'pricing.recharge', value: tariff.price - this.app.account.balance };
          tariff.selected = false;
          tariff.buttonText = false;
          tariff.buttonType = 'primary';
          tariff.click = () => this.recharge(tariff.button.value);
        }
        else {
          tariff.button = { key: 'common.subscribe'};
          tariff.selected = false;
          tariff.buttonText = false;
          tariff.buttonType = 'primary';
          tariff.click = this.changeTariff;
        }
      })
      
      if (this.app.account === null) {
        this.tariffs[0].button = { key: 'common.get_started'};
        this.tariffs[0].selected = false;
        this.tariffs[0].buttonText = false;
        this.tariffs[0].buttonType = '';
        this.tariffs[0].click = this.changeTariff;
      }
    },

    async recharge(amount) {
      this.$refs.rechargeBalanceView.show(amount);
    },

    async changeTariff(tariff) {
      this.loading = true;

      await performCatchable(async () => {
        await this.app.changeTariff(tariff.id);
        await this.refresh();
      });

      this.loading = false;
    },
  }
};
</script>