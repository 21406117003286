/*eslint no-unused-vars: "off"*/

//import { createApp } from 'vue'
import {
  createApp
} from 'vue/dist/vue.esm-bundler';
import {
  createRouter,
  createWebHistory
} from 'vue-router'

import {
  reactive
} from "vue";

import { createI18n } from 'vue-i18n'
//import vue3GoogleLogin from 'vue3-google-login'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { HeartApp } from './app/app'

import App from "./App.vue";
import HomePage from "./components/HomePage.vue";
import InfoPage from "./components/InfoPage.vue";
import PrivacyPage from "./components/PrivacyPage.vue";
import TermsPage from "./components/TermsPage.vue";
import PricingPage from "./components/PricingPage.vue";
import AccountLogin from "./components/AccountLogin.vue";
import AccountPage from "./components/AccountPage.vue";
import JobCreate from "./components/JobCreate.vue";
import DashboardPage from "./components/DashboardPage.vue";

import { messages } from "./messages";

export var heartApp = null;

var onlyOnWeb = (to, from, next) => {
  if (heartApp.isMobile()) {
    window.open(`https://heart-bit.online${to.path}`, '_blank');
    next(from); 
    return;
  }
  next(); 
};

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [{
    path: '/',
    component: HomePage
  },
  {
    path: '/info',
    component: InfoPage,
    beforeEnter : onlyOnWeb
  },
  {
    path: '/privacy',
    component: PrivacyPage,
    beforeEnter : onlyOnWeb
  },
  {
    path: '/terms',
    component: TermsPage,
    beforeEnter : onlyOnWeb
  },
  {
    path: '/pricing',
    component: PricingPage,
    beforeEnter : onlyOnWeb
  },
  {
    path: '/login',
    component: AccountLogin
  },
  {
    path: '/account',
    component: AccountPage
  },
  {
    path: '/job',
    component: JobCreate
  },
  {
    path: '/dashboard',
    component: DashboardPage
  },
]


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const apiBaseUrl = localStorage.getItem('apiBaseUrl') 
  || (process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5158' 
    : 'https://api.heart-bit.online')

heartApp = reactive(new HeartApp(router, routes, apiBaseUrl));

// 5. Create and mount the root instance.
const app = createApp(App)

const i18n = createI18n({
  // default locale
  locale: heartApp.locale(),
  // translations
  messages
})

console.log(`Current locale -> ${heartApp.locale()}`)

app.use(i18n)

// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)

// Don't show landing on mobile
if (heartApp.isMobile()) {
  router.replace('/dashboard')
}

app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// app.use(vue3GoogleLogin, {
//   //clientId: '71094956700-vt7jtharr414dfhdvikr6083fe7pkorl.apps.googleusercontent.com' // Web
//   clientId: '71094956700-0v9q65i3h7p212usrkdpn6qt4ka1aalu.apps.googleusercontent.com' // Android
// })

GoogleAuth.initialize();

document.title = i18n.global.t('title')

app.mount('#app')