export const performCatchable = function (action) {
    return new Promise((resolve, reject) => {
      action()
        .then(() => resolve(true))
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    })
}

export const wait = m => new Promise(r => setTimeout(r, m))