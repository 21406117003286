export const messages = {
    en: {
      'resources.google-sign-in-button': 'google-sign-in-button/button-en.svg',
      'resources.apple-sign-in-button': 'apple-sign-in-button/button-en.svg',

      'title': 'HeartBit. Websites monitoring and changes detection.',
      'common.dashboard': 'Dashboard',
      'common.back': 'Back',
      'common.login': 'Login',
      'common.upgrade_plan': 'Upgrade plan',
      'common.pricing': 'Pricing',
      'common.account': 'Account',
      'common.cancel': 'Cancel',
      'common.save_changes': 'Save changes',
      'common.save': 'Save',
      'common.logout': 'Logout',
      'common.continue': 'Continue',
      'common.amount_with_currency': 'Amount, RUB',
      'common.recharge_balance': 'Recharge account',
      'common.try_now_for_free': 'Try now for free',

      'common.recharge': 'Recharge',
      'common.get_started': 'Get started',
      'common.subscribe': 'Subscribe',

      'pricing.your_plan': 'Your plan',
      'pricing.title': 'Choose plan for your needs',
      'pricing.subtitle': 'Upgrade as you go any time',
      'pricing.max_pages': 'Max pages to check',
      'pricing.max_frequency': 'Max frequency',
      'pricing.features': 'Features',
      'pricing.recharge': 'Recharge {value} ₽',

      'pricing.once_day': '1 day',
      'pricing.once_hour': '1 hour',
      'pricing.once_15_min': '15 min',
      'pricing.continuosly': '5 min (continuously)',

      'pricing.tariff_free': '«Free»',
      'pricing.tariff_small': '«Five»',
      'pricing.tariff_medium': '«Fifteen»',
      'pricing.tariff_large': '«Fourty»',

      'pricing.tariff_free_price': '0₽',
      'pricing.tariff_small_price': '199₽/mo',
      'pricing.tariff_medium_price': '499₽/mo',
      'pricing.tariff_large_price': '1599₽/mo',

      'pricing.tariff_free_pages': '1 page',
      'pricing.tariff_small_pages': '10 pages',
      'pricing.tariff_medium_pages': '15 pages',
      'pricing.tariff_large_pages': '40 pages',

      'pricing.tariff_free_features': '<p>App notifications</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>',
      'pricing.tariff_small_features': '<p>App notifications</p><p>Email notifications</p><p>1 email in CC</p><p>&nbsp;</p>',
      'pricing.tariff_medium_features': '<p>App critical notifications</p><p>Email notifications</p><p>3 emails in CC</p><p>Telegram notifications</p>',
      'pricing.tariff_large_features': '<p>App critical notifications</p><p>Email notifications</p><p>10 emails in CC</p><p>Telegram notifications</p>',

      'account.name': 'Name',
      'account.name_placeholder': '',
      'account.email': 'Email',
      'account.your_balance': 'Your balance',
      'account.your_plan_is': 'Your plan is <a class="button-link" href="pricing"><b>{value}</b></a>.',
      'account.you_pay_per_month': 'You pay {value}₽ per month.',
      'account.if_you_change_email': 'If you change account\'s email <b>you will be logged out</b>.<br/>Then you have to login using new email.',
      'account.menu.profile_and_billing': 'Profile & Balance',
      'account.menu.support': 'Support',

      'payment.agree_with_paymentterms': 'I agree with terms of use and payments',

      'footer.contacts': 'Contacts',
      'footer.company': 'Site',
      'footer.info': 'Info',
      'footer.privacy': 'Privacy policy',
      'footer.terms': 'Terms of use',

      'login.title': 'Login',
      'login.we_will_send_verification': 'We will send verification link.<br/>You don\'t need to remember password<br/><b>Just tap on link</b> from email to login.',
      'login.tap_on_link': 'We just sent you verification email.<br/>Tap on link from email to complete login.',
      'login.privacy_and_terms': 'By continuing, you agree with <a target="_blank" :underline="false" href="https://heart-bit.online/privacy" class="clickable no-underline dont-break-out color-primary">privacy policy</a> and <a target="_blank" :underline="false" href="https://heart-bit.online/terms" class="clickable no-underline dont-break-out color-primary">terms of use</a>.',

      'home.heartbit_landing_title': 'Websites <span style="background-color: #1e90ff22;border: 1px solid transparent;border-radius: 0px;color: #0081ff;">changes&nbsp;detection</span>',
      'home.heartbit_footer_text': 'Web monitoring and changes detection service',
      'home.heartbit_landing_subtitle': 'of your business, competitors, shops sales',

      'home.get_alerts_when_we_detect_changes': 'Get alerts when we detect changes',
      'home.when_a_change_is_detected': 'When a change is detected, we\'ll notify you with a screenshot of the page, hightlighting what\'s changes.',

      'home.various_user_cases': 'Various use cases',
      'home.we_detect_visual_changes': 'We detect visual changes on web-sites, but also when site is down, slow or doesn\'t works as expected.',

      'home.easy_to_use': 'Easy to use',
      'home.just_enter_the_page_you_want': 'Just enter the page you want to monitor. Our alerts will show you what changes. Your can choose way to receive alerts — by email, in Telegram or in our app.',
    
      'home.get_alerts_everywhere': 'Get alerts everywhere, any time',
      'home.choose_way_to_get_alerts': 'Choose way to get changes alerts — no matter where you\'re accessing the internet. We can send notifications by email, in Telegram... and also in mobile app!',
      'home.coming_soon': 'coming soon',

      'home.welcome_to_alplha_testing': 'Welcome to alpha testing!',
      'home.we_have_launched_our_service': 'We have launched our service recently in alpha testing and happy to get new users. Feel free to send bug reports and valuable feedback on our email!',

      'dashboard.dashboard': 'Dashboard',
      'dashboard.create_job': 'Create job',
      'dashboard.checks_log': 'Checks logs',
      'dashboard.next_check': 'Next check',
      'dashboard.active_jobs': 'Active jobs',
      'dashboard.check_now': 'Check now',
      'dashboard.details': 'Details',
      'dashboard.show_only_changes': 'Show only changes',
      'dashboard.search_job_placeholder': 'Search job',
      'dashboard.job_edit': 'Edit job',
      'dashboard.edit_notifications': 'Alert settings',
      'dashboard.no_jobs': 'No websites monitoring jobs',

      'new_job.input_page_url': 'Input page url',
      'new_job.title': 'New job',
      'new_job.edit': 'Edit job',
      'new_job.advanced_settings': 'Advanced settings',
      'new_job.you_have_maximum_jobs': 'You have maximum jobs. Upgrade plan to create more jobs.',
      'new_job.page_address': 'Page address',
      'new_job.job_name': 'Job name',
      'new_job.job_name_placeholder': 'Yandex | Finds everything',
      'new_job.check_frequency': 'Checks frequency',
      'new_job.check_time': 'Check time',
      'new_job.start_monitoring': 'Start monitoring',
      'new_job.save_changes': 'Save changes',
      'new_job.configure': 'Configure',
      'new_job.select_checks_frequency': 'Set job\'s name and choose checks frequency',
      'new_job.wait_after_load': 'Wait after page loaded',
      'new_job.screen_size': 'Screen parameters',
      'new_job.disable_javascript': 'Disable JavaScript',
      'new_job.parameter_not_supported': 'Selected parameters not supported by your tariff plan',
      'new_job.select_area_1': 'Select area for changes detection. You can ',
      'new_job.select_area_2': 'select whole page.',
      'new_job.url_placeholder': 'For example, https://en.wikipedia.org/wiki/Dinosaur',

      'frequency.every_5_min': 'continuously',
      'frequency.every_30_min': 'every 30 min',
      'frequency.every_15_min': 'every 15 min',
      'frequency.every_10_min': 'every 10 min',
      'frequency.every_1_hour': 'every hour',
      'frequency.every_3_hours': 'every 3 hours',
      'frequency.every_12_hours': 'every 12 hours',
      'frequency.every_1_day': 'every day',
    },
    ru: {
      'resources.google-sign-in-button': 'google-sign-in-button/button-ru.svg',
      'resources.apple-sign-in-button': 'apple-sign-in-button/button-ru.svg',

      'title': 'HeartBit. Мониторинг и отслеживание изменений на сайтах.',
      'common.dashboard': 'Дэшборд',
      'common.back': 'Назад',
      'common.login': 'Войти',
      'common.upgrade_plan': 'Изменить тариф',
      'common.pricing': 'Тарифы',
      'common.account': 'Аккаунт',
      'common.cancel': 'Отмена',
      'common.save_changes': 'Сохранить изменения',
      'common.save': 'Сохранить',
      'common.logout': 'Выйти из системы',
      'common.continue': 'Продолжить',
      'common.amount_with_currency': 'Сумма, РУБ',
      'common.recharge_balance': 'Пополнить аккаунт',
      'common.try_now_for_free': 'Попробовать бесплатно',

      'common.recharge': 'Пополнить баланс',
      'common.get_started': 'Начать пользоваться',
      'common.subscribe': 'Перейти',

      'pricing.your_plan': 'Ваш тариф',
      'pricing.title': 'Выберите тариф под свои задачи',
      'pricing.subtitle': 'Если будет не хватать — в любое время тариф можно поменять.',
      'pricing.max_pages': 'Количество страниц',
      'pricing.max_frequency': 'Максимальная частота',
      'pricing.features': 'Фичи',
      'pricing.recharge': 'Не хватает {value} ₽',

      'pricing.once_day': '1 сутки',
      'pricing.once_hour': '1 час',
      'pricing.once_15_min': '15 мин',
      'pricing.continuosly': '5 мин (непрерывно)',

      'pricing.tariff_free': '«Бесплатный»',
      'pricing.tariff_small': '«Пять»',
      'pricing.tariff_medium': '«Пятнадцать»',
      'pricing.tariff_large': '«Сорок»',

      'pricing.tariff_free_price': '0₽',
      'pricing.tariff_small_price': '199₽ /мес',
      'pricing.tariff_medium_price': '499₽ /мес',
      'pricing.tariff_large_price': '1599₽ /мес',

      'pricing.tariff_free_pages': '1 страница',
      'pricing.tariff_small_pages': '5 страниц',
      'pricing.tariff_medium_pages': '15 страниц',
      'pricing.tariff_large_pages': '40 страниц',

      'pricing.tariff_free_features': '<p>Уведомление в приложение</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>',
      'pricing.tariff_small_features': '<p>Уведомление в приложение</p><p>Уведомление на почту</p><p>1 получатель</p><p>&nbsp;</p>',
      'pricing.tariff_medium_features': '<p>Critical alert в приложение</p><p>Уведомление на почту</p><p>3 получателя</p><p>Telegram уведомление</p>',
      'pricing.tariff_large_features': '<p>Critical alert в приложение</p><p>Уведомление на почту</p><p>10 получателей</p><p>Telegram уведомление</p>',

      'account.name': 'Имя',
      'account.name_placeholder': '',
      'account.email': 'Email',
      'account.your_balance': 'Ваш баланс',
      'account.your_plan_is': 'Ваш тариф — <a class="button-link" href="pricing"><b>{value}</b></a>.',
      'account.you_pay_per_month': 'Вы платите {value}₽ в месяц.',
      'account.menu.profile_and_billing': 'Профиль и Баланс',
      'account.menu.support': 'Поддержка',
      'account.if_you_change_email': 'При изменении email <b>вы будете автоматически разлогинены</b>.<br/>Далее просто войдите, используя новый email.',

      'payment.agree_with_paymentterms': 'Я согласен с пользовательским соглашением',

      'footer.contacts': 'Контакты',
      'footer.company': 'Сайт',
      'footer.info': 'О нас',
      'footer.privacy': 'Политика обработки персональных данных',
      'footer.terms': 'Пользовательское соглашение',

      'login.title': 'Вход',
      'login.we_will_send_verification': 'Мы отправим проверочную ссылку. Вам не требуется устанавливать пароль. <b>Просто перейдите по ссылке</b> из письма.',
      'login.tap_on_link': 'Мы отправили вам проверочное письмо.<br/>Перейдите по ссылке в письме, вход выполнится автоматически.',
      'login.privacy_and_terms': 'Продолжая, вы соглашаетесь с <a target="_blank" :underline="false" href="https://heart-bit.online/privacy" class="clickable no-underline dont-break-out color-primary">политикой конфиденциальности</a> и <a target="_blank" :underline="false" href="https://heart-bit.online/terms" class="clickable no-underline dont-break-out color-primary">пользовательскими условиями</a>.',

      'home.heartbit_landing_title': '<span style="background-color: #1e90ff22;border: 1px solid transparent;border-radius: 0px;color: #0081ff;">Отслеживание изменений</span> на&nbsp;сайтах',
      'home.heartbit_footer_text': 'Сервис веб-мониторинга и отслеживания изменений на сайтах',
      'home.heartbit_landing_subtitle': 'вашего бизнеса, конкурентов, интернет-магазинов',

      'home.get_alerts_when_we_detect_changes': 'Вы получите уведомление, когда мы обнаружим изменения',
      'home.when_a_change_is_detected': 'Мы сообщим вам об изменениях, отправив скриншот сайта и покажем, что поменялось.',

      'home.various_user_cases': 'Для разных целей и задач',
      'home.we_detect_visual_changes': 'Мы обнаруживаем визуальные изменения на сайте, а также когда он "упал", "тормозит" или работает не так как должен.',

      'home.easy_to_use': 'Просто начать, легко пользоваться',
      'home.just_enter_the_page_you_want': 'Просто введите адрес страницы, за изменениями которой вы ходите следить. В уведомлениях будет показано, что изменилось. Вы можете выбрать способ их получения — по почте, в Telegram или в наше мобильное приложение.',
    
      'home.get_alerts_everywhere': 'Получайте уведомления в&nbsp;любом месте, в&nbsp;любое время',
      'home.choose_way_to_get_alerts': 'Выберите способ доставки уведомлений — они будут приходить везде, где есть интернет. Мы можем доставлять их по почте, в Telegram... и в наше приложение!',
      'home.coming_soon': 'оно в разработке',

      'home.welcome_to_alplha_testing': 'Примите участие в тестировании!',
      'home.we_have_launched_our_service': 'Недавно мы запустили наш сервис в тестовом режиме и будем рады обзавестись новыми пользователями. Не стесняйтесь присылать ваши пожелания и найденные баги на нашу почту!',
    
      'dashboard.dashboard': 'Дэшборд',
      'dashboard.create_job': 'Новая задача',
      'dashboard.checks_log': 'История проверок',
      'dashboard.next_check': 'Следующая проверка',
      'dashboard.active_jobs': 'Активные задачи',
      'dashboard.check_now': 'Проверить сейчас',
      'dashboard.details': 'Подробнее',
      'dashboard.show_only_changes': 'Показывать только изменения',
      'dashboard.search_job_placeholder': 'Поиск',
      'dashboard.job_edit': 'Редактировать задачу',
      'dashboard.edit_notifications': 'Настройки уведомлений',
      'dashboard.no_jobs': 'Нет задач на мониторинг сайтов',

      'new_job.input_page_url': 'Введите адрес страницы',
      'new_job.title': 'Новая задача',
      'new_job.edit': 'Редактирование задачи',
      'new_job.advanced_settings': 'Расширенные настройки',
      'new_job.you_have_maximum_jobs': 'Вы создали максимальное количество задач. Перейдите на другой тариф, чтобы создать больше задач.',
      'new_job.page_address': 'Адрес страницы',
      'new_job.job_name': 'Название задачи',
      'new_job.job_name_placeholder': 'Название задачи',
      'new_job.check_frequency': 'Частота проверок',
      'new_job.check_time': 'Время проверки',
      'new_job.start_monitoring': 'Начать мониторинг',
      'new_job.save_changes': 'Сохранить изменения',
      'new_job.configure': 'Настройки',
      'new_job.select_checks_frequency': 'Дайте задаче название и выберите частоту проверок',
      'new_job.wait_after_load': 'Ожидание после загрузки страницы',
      'new_job.screen_size': 'Параметры экрана',
      'new_job.disable_javascript': 'Отключить JavaScript',
      'new_job.parameter_not_supported': 'Выбранные параметры не поддерживаются вашим тарифом',
      'new_job.select_area_1': 'Выберите область, изменения которой нужно отслеживать. Можно ',
      'new_job.select_area_2': 'выбрать сразу всю страницу.',
      'new_job.url_placeholder': 'Например, https://ru.wikipedia.org/wiki/Динозавры',

      'frequency.every_5_min': 'непрерывно',
      'frequency.every_30_min': 'каждые 30 мин',
      'frequency.every_15_min': 'каждые 15 мин',
      'frequency.every_10_min': 'каждые 10 мин',
      'frequency.every_1_hour': 'каждый час',
      'frequency.every_3_hours': 'каждые 3 часа',
      'frequency.every_12_hours': 'каждые 12 часов',
      'frequency.every_1_day': 'каждый день',
    }
  }