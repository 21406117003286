import {
    axiosApiInstance
} from './axios-interceptor'

import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';

export class HeartApp {

    constructor(router, routes, baseUrl) {
        this.router = router;
        this.routes = routes;
        this.isAuthorized = null;
        this.callbackWaiting = null;
        this.account = null;
        this.platform = Capacitor.getPlatform();
        
        this.checkIsAuthorized();

        axiosApiInstance.defaults.baseURL = baseUrl;
        axiosApiInstance.refreshAccessToken = () => {
            return new Promise((resolve, reject) => {

                if (this.tryNavigateToLogin()) {
                    return;
                }

                axiosApiInstance.post(`/auth/token/refresh/`, {
                        accessToken: localStorage.getItem('token'),
                        refreshToken: localStorage.getItem('refreshToken'),
                        fingerprint: localStorage.getItem('fingerprint')
                    })
                    .then((response) => {
                        console.log(response);

                        if (response.data.refreshToken && response.data.token) {
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('refreshToken', response.data.refreshToken);

                            this.checkIsAuthorized();

                            resolve(response.data.token);
                        }
                        else {
                            throw 'Refresh token or access token is empty';
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                        reject(error);

                        if (error.response.status === 401) {
                            console.log('Refresh token error -> unauthorized. Please, login again');

                            this.tryNavigateToLogin();
                        }
                    });
            })
        }
        
        // Handle firebase token refresh from native platform
        window.onFirebaseTokenRefreshed = () => {        
            if (window.firebaseToken == undefined 
                || window.firebaseToken == null
                || window.firebaseToken == '') {

                console.log(`Nothing to send -> ${window.firebaseToken}`);
                return false;
            }
            
            console.log(`Pending firebase token -> ${window.firebaseToken}`);

            this.saveFirebaseToken(window.firebaseToken);
            this.sendFirebaseToken();

            return true;
        }

        // Firebase token can be appeared on native level before this code executed
        // So we need to check pending token, save it and send to backend
        if (!window.onFirebaseTokenRefreshed()) {
            this.sendFirebaseToken();
        }
        
        // Listen for "hardware" back button pressed (works only on Android)
        CapacitorApp.addListener('backButton', ({canGoBack}) => {
            if(!canGoBack){
                CapacitorApp.exitApp();
            } else {
                window.history.back();
            }
        });
        
        var updateScreenSize = () => {
            this.screenWidth = document.body.clientWidth;
            this.screenHeight = document.body.clientHeight;
        }
        
        updateScreenSize();
        
        window.addEventListener('resize', updateScreenSize);
    }

    locale() {
        return localStorage.getItem('locale') || navigator.language || navigator.userLanguage || 'en-US';
    }

    isWeb() {
        return this.platform == 'web';
    }

    isMobile() {
        return this.platform != 'web';
    }

    getAppBarIcon(route) {
        if (!this.isMobile()) {
            return null;
        }
        
        if (route.path == '/login') {
            return null;
        }
        
        if (!route.hash && route.path == '/dashboard') {
            return 'menu';
        }

        return 'back';
    }

    checkIsAuthorized() {
        if (localStorage.getItem('token') && localStorage.getItem('refreshToken')) {
            this.isAuthorized = true;
            this.callbackWaiting = false;
            return;
        } 
            
        this.isAuthorized = false;

        if (localStorage.getItem('callbackToken') && localStorage.getItem('fingerprint')) {
            this.callbackWaiting = true;
        } else {
            this.callbackWaiting = false;
        }
    }

    tryNavigateToLogin() {

        if (this.isAuthorized) {
            return false;
        }

        if (window.location.pathname.startsWith('/login') === true) {
            return false;
        }

        this.router.replace({
            path: 'login',
            query: { url: window.location.pathname }
        });

        return true;
    }

    navigateToDashboard() {
        this.router.replace({
            path: '/dashboard'
        });
    }

    created(component) {
        this.checkIsAuthorized();

        if (component.$options.onlyAnonymous === true && this.isAuthorized === true) {
            this.navigateToDashboard();
            return;
        }

        if (component.$options.onlyAuthorized === true && !this.isAuthorized) {
            this.tryNavigateToLogin();
            return;
        }
    }

    saveFirebaseToken(firebaseToken) {
        localStorage.setItem('firebaseToken', firebaseToken);
    }

    sendFirebaseToken() {
        var firebaseToken = localStorage.getItem('firebaseToken');

        // Don't send token if not authorized yet
        if (this.isAuthorized != true 
            || firebaseToken == undefined
            || firebaseToken == null 
            || firebaseToken == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/auth/firebase-token', {
                firebaseToken, 
            })
            .then((response) => {
                console.log(response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    fetchUrl(url, parameters) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/api/fetch-url', {
                    url, 
                    parameters
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    jobEnqueueCheck(jobId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post(`/api/job/${jobId}/enqueue-check`)
                .then((response) => {
                    console.log(response);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        })
    }

    refreshAccount() {
        return new Promise((resolve, reject) => {
            if (this.isAuthorized === true) {
                axiosApiInstance.get(`/api/account`)
                    .then((response) => {
                        console.log(response);
                        this.account = response.data;
                        resolve(this.account);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });
            }
            else {
                resolve(null);
            }
        })
    }

    updateAccount(name, email) {

        if (!email) {
            throw 'Email is null or empty string';
        }

        return new Promise((resolve, reject) => {
            axiosApiInstance.post(`/api/account/update`, {
                name,
                email
            })
            .then(async (response) => {
                console.log(response);

                if (response.data.shouldLogout === true) {
                    await this.logout();
                }

                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            });
        })
    }



    changeTariff(tariffId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post(`/api/account/change-tariff`, {
                tariffId,
            })
            .then((response) => {
                console.log(response);
                resolve(response.data);
                })
            .catch((error) => {
                console.log(error);
                reject();
            });
        })
    }

    getBeatScreenshot(beatId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.get(`/api/beat/${beatId}/screenshot`, {
                responseType: 'blob',
            })
            .then((response) => {
                console.log(response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            });
        })
    }

    getBeatChangesScreenshot(beatId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.get(`/api/beat/${beatId}/changes-screenshot`, {
                responseType: 'blob',
            })
            .then((response) => {
                console.log(response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            });
        })
    }

    getBeats(jobId, createdFrom, skip, limit, onlyChanges) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.get(`/api/job/${jobId}/beat`, {
                params: {
                    createdFrom,
                    skip,
                    limit,
                    onlyChanges
                }
            })
            .then((response) => {
                console.log(response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            });
        })
    }

    getJobs() {
        return new Promise((resolve, reject) => {
            axiosApiInstance.get(`/api/job`)
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        })
    }

    getJob(jobId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.get(`/api/job/${jobId}`)
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        })
    }

    createJob(jobId, url, jobName, cron, parameters) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post(`/api/job`, {
                    jobId,
                    url,
                    jobName,
                    cron,
                    parameters
                })
                .then((response) => {
                    console.log(response);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        })
    }

    register(userName, password, name) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/auth/register', {
                    userName,
                    password,
                    name
                })
                .then((response) => {
                    console.log(response);

                    resolve();
                })
                .catch((error) => {
                    console.log(error);

                    reject(error);
                });
        });
    }

    logout() {

        // TODO: perform API call before

        localStorage.removeItem('fingerprint');
        localStorage.removeItem('callbackToken');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        
        window.location.reload();

        this.checkIsAuthorized();
    }

    async login(email, googleToken, appleToken) {
        // Email or google/apple auth token should be specified
        if (!this.validateEmail(email) && !googleToken && !appleToken) {
            return;
        }

        var deviceInfo = await Device.getInfo();

        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/auth/login', {
                    email,
                    googleToken,
                    appleToken,
                    platform: this.platform,
                    deviceInfo : deviceInfo,
                })
                .then((response) => {
                    console.log(response);

                    localStorage.setItem('email', email);
                    localStorage.setItem('fingerprint', response.data.fingerprint);
                    localStorage.setItem('callbackToken', response.data.callbackToken);

                    this.checkIsAuthorized();

                    resolve();
                })
                .catch((error) => {
                    console.log(error);

                    reject(error);
                });
        });
    }

    cancelLogin() {
        localStorage.removeItem('email');
        localStorage.removeItem('fingerprint');
        localStorage.removeItem('callbackToken');

        this.checkIsAuthorized();
    }

    loginCallback() {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/auth/login-callback', {
                    fingerprint: localStorage.getItem('fingerprint'),
                    callbackToken: localStorage.getItem('callbackToken'),
                })
                .then((response) => {
                    console.log(response);

                    if (response.data.completed === true) 
                    {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('refreshToken', response.data.refreshToken);
        
                        this.checkIsAuthorized();
                        this.sendFirebaseToken();
                        
                        this.router.replace({
                            path: new URL(location).searchParams.get('url') ?? '/dashboard'
                        });

                        resolve(true);
                    }
                    else
                    {
                        resolve(false);
                    }
                    
                })
                .catch((error) => {
                    console.log(error);

                    reject(error);
                });
        });
    }

    performPayment(amount, containerId) {
        return new Promise((resolve, reject) => {
            axiosApiInstance.post('/api/account/payment', {
                    amount
                })
                .then((response) => {
                    console.log(response);

                    const checkout = new window.YooMoneyCheckoutWidget({
                        confirmation_token: response.data.confirmationToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                        return_url: response.data.returnUrl, //Ссылка на страницу завершения оплаты, это может быть любая ваша страница
            
                        //При необходимости можно изменить цвета виджета, подробные настройки см. в документации
                        customization: {
                          //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                            colors: {
                                //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                                control_primary: '#ff1744', //Значение цвета в HEX
                  
                                //Цвет платежной формы и ее элементов
                                control_primary_content: '#ffffff' //Значение цвета в HEX
                            }
                        },

                        error_callback: function(error) {
                            console.log(error)
                        }
                    });
            
                    checkout.render(containerId);

                    resolve();
                })
                .catch((error) => {
                    console.log(error);

                    reject(error);
                });
        });
    }

    validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
}