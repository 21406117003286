<template>

    <div>

        <div class="center landing-container" style="padding:32px; max-width: 700px;">

            <h3>Контакты</h3>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Email</p>
                    <el-link :underline="false" class="clickable dont-break-out" href="mailto:support@heart-bit.online">support@heart-bit.online</el-link>
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Телефон</p>
                    <el-link :underline="false" class="clickable dont-break-out" href="tel://+375259723759">+375 25 972-37-59</el-link>
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Мессенджеры</p>
                    <el-link target="_blank" :underline="false" class="clickable dont-break-outy" href="https://t.me/heartbit_support">Telegram</el-link>
                    <br/>
                    <el-link target="_blank" :underline="false" class="clickable dont-break-out" href="https://wa.me/375259723759">WhatsApp</el-link>
                </div>
            </el-row>

            <el-divider/>

            <h3>Реквизиты</h3>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">ФИО</p>
                    Кочетков В.В.
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">ИНН</p>
                    503218982900
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Банк</p>
                    АО "АЛЬФА-БАНК"
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">БИК</p>
                    044525593
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Расчётный счёт</p>
                    40817810206350098579
                </div>
            </el-row>
            <el-row class="container-small">
                <div>
                    <p class="label-secondary">Корр. счёт банка</p>
                    30101810200000000593
                </div>
            </el-row>
        </div>
        
        <div style="background: #e3f2fd;">
            <div class="center landing-container" style="padding:32px; max-width: 700px;">

                <h2>{{$t('home.welcome_to_alplha_testing')}}</h2>

                <p class="label-text" style="margin-bottom:32px;">
                {{$t('home.we_have_launched_our_service')}}
                <br/>
                <el-link target="_blank" :underline="false" class="color-primary clickable dont-break-out" href="mailto:support@heart-bit.online">support@heart-bit.online</el-link>
                </p>

                <el-button type="primary" v-on:click="this.$router.push({ path: 'login' })">{{$t('common.try_now_for_free')}} <img class="button-icon" src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/325/love-you-gesture_1f91f.png"/></el-button>
                
            </div>
        </div>
    
    </div>
    
</template>
<style scoped>
.li {
    margin: 12px 0px;
}
</style>